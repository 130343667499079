import { createAction, props } from '@ngrx/store';

export const COM_PROGRESS_RESET  =
  createAction ( '[COM] Progress RESET' );

export const COM_PROGRESS_INCREMENT  =
               createAction ( '[COM] Progress INCREMENT',
                 props<{ url: string }> () );
export const COM_PROGRESS_DECREMENT  =
               createAction ( '[COM] Progress DECREMENT',
                 props<{ url: string }> () );
