<div class="toggle-button" (click)="drawer.toggle()">
  <mat-icon *ngIf="drawer.opened; else showInfoIcon">close</mat-icon>
  <ng-template #showInfoIcon>
    <mat-icon>info</mat-icon>
  </ng-template>
</div>

<mat-drawer-container hasBackdrop="false">
<!--  <div class="toggle-button">-->
<!--    <mat-icon (click)="drawer.toggle()">info</mat-icon>-->
<!--  </div>-->

  <mat-drawer #drawer mode="side" position="end" opened>
    <ng-container *ngFor="let shorthandIcon of shorthandIcons">
      <a class="link" [routerLink]="shorthandIcon.link">
        <ed-clients-lazy-bb-picture
          class="contain"
          [blackbirdImage]="shorthandIcon.icon">
        </ed-clients-lazy-bb-picture>
      </a>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>
