import { BlackbirdPagination, BlackbirdPost } from '@ed-clients/shared';
import { createAction, props } from '@ngrx/store';

// similar to references store, but news store doesnt support categories
export const NEWS_LOAD = createAction ( '[NEWS] LOAD NEWS',
  props<{ page?: number, limit?: number, query?: string }> ()
);

export const NEWS_SET = createAction ( '[NEWS] SET NEWS',
  props<{ news: BlackbirdPost[] }> ()
);

export const NEWS_LAZY_LOADED_SET = createAction ( '[NEWS] LAZY LOADED SET NEWS',
  props<{ news: BlackbirdPost[] }> ()
);

export const NEWS_SET_Pagination = createAction ( '[NEWS] SET NEWS PAGINATION',
  props<{ pagination: BlackbirdPagination }> ()
);
