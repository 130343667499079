import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientEnvironment, ENVIRONMENT } from '../common-injection-token';
import { Schema } from '../seo/meta.interface';
import { BlackbirdConfig } from './blackbird.interface';
import { Content } from './content.interface';

@Injectable ( {
  providedIn: 'root',
} )
export class MetaInfoService {
  defaultSeoData: BlackbirdConfig;
  public schemas: BehaviorSubject<Schema[]> = new BehaviorSubject ( null );
  private schemaSubject = new BehaviorSubject ( null );

  constructor(
    @Inject ( ENVIRONMENT ) private environment: ClientEnvironment,
    @Inject ( PLATFORM_ID ) private platformId: string,
    @Inject ( DOCUMENT ) private doc: Document,
    private title: Title,
    private meta: Meta,
    private store: Store,
  ) {
  }

  get schema(): Observable<any> {
    return this.schemaSubject.asObservable ();
  }

  /**
   * Updates meta tags, title and JSON-LD data.
   * There is no need to update the tags after the content get pulled from the transfer state so its optional.
   * @param content
   * @param withTags
   */
  updateMetaInfos( seoContent: Content, webHost: string, withTags = true ) {
    const content: Content = { ...seoContent }; // data from api (blackbird or shopware) is immutable, clone it first
    content.seo            = { ...content.seo, description: this.stripHtml ( content.seo.description )
                                                                .substr ( 0, 157 ) + '...',
    };
    content.socialMedia    = { ...content.socialMedia, description: this.stripHtml ( content.socialMedia.description )
                                                                        .substr ( 0, 157 ) + '...',
    };

    if ( withTags ) {
      this.removeOldMetaTags ();
      this.addCanonicalLink ( content, webHost );

      this.meta.addTag ( { property: 'og:type', content: 'website' } );

      if ( content.seo ) {
        this.addMetaDescriptionAndTitle ( content.seo );
      }

      if ( content.socialMedia ) {
        // Twitter metadata
        this.addDefaultTwitterMetaInfo ( content.socialMedia );
        this.addTwitterOgTitle ( content.socialMedia );
        this.addTwitterOgDescription ( content.socialMedia );
        this.addSeoTwitterOgImg ( content.socialMedia );
        this.addSeoOgTwitterKeywords ( content.socialMedia );
      }

      if ( content.schema ) {
        // this.addSchemaUrlInfo(content.schema);
      }
    }

    if ( content.schemas ) {
      this.schemaSubject.next ( content.schemas );

    }
  }

  // public addSeoSchemas( realEstateSchema ) {
  //     const currentSeoSchema  = this.schemaSubject.getValue ();
  //     const enrichedSeoSchema = [];
  //     if ( currentSeoSchema ) {
  //         currentSeoSchema.forEach ( it => {
  //             enrichedSeoSchema.push ( it );
  //         } );
  //         enrichedSeoSchema.push ( realEstateSchema );
  //         this.schemaSubject.next ( enrichedSeoSchema );
  //     }
  // }

  private addSchemaUrlInfo( schema ) {
    if ( schema.url ) {
      this.meta.addTag ( {
        property: 'og:url', content: schema.url.toString ()
                                           .replace ( 'https', 'http' ),
      } );
    }
  }

  private addCanonicalLink( content, webHost ): void {
    if ( content ) {
      this.doc.querySelectorAll ( 'link[rel="canonical"]' )
          .forEach ( item => {
            item.remove ();
          } );
      const title: Title      = this.title as Title;
      // tslint:disable-next-line
      let url: string         = title[ '_doc' ].documentURI;
      const indexOfQM: number = url.indexOf ( '?' );
      if ( indexOfQM > - 1 ) {
        url = url.slice ( 0, indexOfQM );
      }
      url                         = (isPlatformServer ( this.platformId ) ? webHost : '') +
        url.replace ( /(\s|\/)*$/, '' ) +
        '/';
      const link: HTMLLinkElement = this.doc.createElement ( 'link' );
      link.setAttribute ( 'rel', 'canonical' );
      this.doc.head.appendChild ( link );
      link.setAttribute ( 'href', url );
    }
  }

  private addSeoOgTwitterKeywords( socialMedia ) {
    if ( socialMedia.keywords ) {
      this.meta.addTag ( { property: 'twitter:keywords', content: socialMedia.keywords } );
      this.meta.addTag ( { property: 'og:keywords', content: socialMedia.keywords } );
    }
  }

  private addSeoTwitterOgImg( socialMedia ) {
    if ( socialMedia.image ) {
      const url = socialMedia.image; // .replace( 'https', 'http' );
      this.meta.addTag ( { name: 'twitter:image', content: url } );
      this.meta.addTag ( { name: 'og:image', content: url } );
      this.meta.addTag ( { property: 'og:image', content: url } );
      this.meta.addTag ( { name: 'image', content: url } );
    }
  }

  private addTwitterOgDescription( socialMedia ) {
    if ( socialMedia.description ) {
      this.meta.addTag ( { property: 'twitter:description', content: socialMedia.description } );
      this.meta.addTag ( { property: 'og:description', content: socialMedia.description } );
    }
  }

  private addTwitterOgTitle( socialMedia ) {
    if ( socialMedia.title ) {
      this.meta.addTag ( { property: 'twitter:title', content: socialMedia.title } );
      this.meta.addTag ( { property: 'og:title', content: socialMedia.title } );
    }
  }

  private addDefaultTwitterMetaInfo( socialMedia ) {
    this.meta.addTag ( { name: 'twitter:card', content: 'summary' } );
    if ( socialMedia.twitterId ) {
      this.meta.addTag ( { name: 'twitter:site', content: `@${socialMedia.twitterId}` } );
    }
  }

  private addMetaDescriptionAndTitle( seo ) {
    if ( seo.title ) {
      this.title.setTitle ( seo.title );
    }
    if ( seo.description ) {
      this.meta.addTag ( { name: 'description', content: seo.description } );
    }
  }

  private removeOldMetaTags() {
    this.meta.removeTag ( `name='description'` );
    this.meta.removeTag ( `name='twitter:card'` );
    this.meta.removeTag ( `name='twitter:site'` );

    this.meta.removeTag ( `name='twitter:title'` );
    this.meta.removeTag ( `name='og:title'` );
    this.meta.removeTag ( `property='twitter:title'` );
    this.meta.removeTag ( `property='og:title'` );

    this.meta.removeTag ( `name='twitter:description'` );
    this.meta.removeTag ( `name='og:description'` );
    this.meta.removeTag ( `property='twitter:description'` );
    this.meta.removeTag ( `property='og:description'` );

    this.meta.removeTag ( `name='twitter:image'` );
    this.meta.removeTag ( `name='og:image'` );
    this.meta.removeTag ( `property='twitter:image'` );
    this.meta.removeTag ( `property='og:image'` );
    this.meta.removeTag ( `name='image'` );

    this.meta.removeTag ( `name='twitter:keywords'` );
    this.meta.removeTag ( `name='og:keywords'` );
    this.meta.removeTag ( `property='twitter:keywords'` );
    this.meta.removeTag ( `property='og:keywords'` );
    this.meta.removeTag ( `property='og:type'` );
    this.meta.removeTag ( `property='og:url'` );
  }

  private stripHtml( html: string ): string {
    return html?.replace ( /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '' ) || '';
  }

}
