import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DynamicPaginatorComponent } from './dynamic-paginator.component';



@NgModule({
  declarations: [DynamicPaginatorComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [DynamicPaginatorComponent]
})
export class DynamicPaginatorModule { }
