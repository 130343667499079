import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClientEnvironment, ENVIRONMENT } from '../common-injection-token';
import { BlackbirdStaticpageResponse } from './blackbird.interface';
import { Content } from './content.interface';

@Injectable ( {
  providedIn: 'root'
} )
export class ContentResolverService implements Resolve<Content> {

  constructor (
    @Inject ( PLATFORM_ID ) private platformId: string,
    private http: HttpClient,
    @Inject ( ENVIRONMENT ) private environment: ClientEnvironment
  ) {
  }

  resolve ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Content> {
    let envData;
    if ( !route.data.environment ) {
      envData = this.environment;
    } else {
      envData = route.data.environment;
    }

    if ( route.data.content.queryString !== '' && !route.data.content.queryString ) {
      // can use '' for queryString to get image string
      route.data.content.queryString = '?images=objects';
    }

    let params: string | number;
    if ( route.params.slug ) {
      params = `-${route.params.slug}`;
    } else {
      params = '';
    }

    const slug        = envData.blackbird.projectSlug + '-' + route.data.content.slug + params;
    const queryString = route.data.content.queryString;

    return this.http.get<BlackbirdStaticpageResponse> ( `${envData.browser.jsonHost}/${envData.blackbird.content}/${slug}${queryString}` )
               .pipe (
                 map ( data => data.data ),
                 catchError ( ( err ) => {
                   if ( err.status === 404 ) {
                     console.error ( `Blackbird staticpage ${err.url} doesn't exist or is inavtive` );
                   }
                   console.log ( 'Blackbird error: ', err );
                   return EMPTY; // throwError ( err.toString () );
                 } )
               );
  }

}
