import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientEnvironment, ENVIRONMENT } from '../common-injection-token';
import { RealEstate, SwStoreResponse } from './shopware.interface';

@Injectable ( {
  providedIn: 'root',
} )
export class RealEstateResolver implements Resolve<RealEstate[] | RealEstate> {

  constructor( @Inject ( PLATFORM_ID ) private platformId,
               private http: HttpClient,
               @Inject( ENVIRONMENT ) private environment: ClientEnvironment ) {
  }

  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<RealEstate[] | RealEstate> {
    const body    = {

      associations: {
        media: [],
      },
      includes    : {
        product: ['id',
                  'name',
                  'customFields',
                  'description',
                  'markAsTopseller',
                  'media',
                  'cover',
        ],
      },
      filter      : [{ type: 'equals', field: 'vendor.id', value: this.environment.shopware.vendorId }],
    };
    const options = { headers: { 'sw-access-key': this.environment.shopware.realEstatesAccessKey } };
    return this.http.post<SwStoreResponse<RealEstate>> ( this.getUrl ( this.environment ), body, options )
               .pipe (
                 map(swRes => swRes.elements),
                 map( realEstates => {
                   if( route.params.id ) {
                     return realEstates.filter( realEstate => realEstate.id === route.params.id )
                   } else {
                     return realEstates
                   }
                 } )
               );
  }

  private getUrl( environment ): string {
    let host = environment.browser.shopHost;
    if ( isPlatformServer ( this.platformId ) ) {
      host = environment.ssr.shopHost;
    }
    return host + environment.shopware.products;
  }
}
