import { Action, createReducer, on } from '@ngrx/store';
import { errorInitState, ErrorState } from './errorInitState';
import { ERROR_RESET, ERROR_SET, ERROR_SET_IS_FATAL } from './error.actions';

const _errorReducer = createReducer (
  errorInitState,
  on ( ERROR_SET, ( state, { active } ) => ({ ...state, active }) ),
  on ( ERROR_SET_IS_FATAL, ( state, { isFatal } ) => ({ ...state, isFatal }) ),
  on ( ERROR_RESET, ( state ) => ({ ...state, active: undefined }) )
);

export const errorReducer = ( state: ErrorState, action: Action ) =>
  _errorReducer ( state, action );
