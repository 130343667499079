import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {selectConfigState} from '@nettrek-angular-libs/common-store';

import {Config} from '../app-init.interface';
import {NavData} from './header.interface';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {OnDestroyMixin, untilComponentDestroyed} from "@w11k/ngx-componentdestroyed";

interface NavEntry extends NavData {
  isOpen: boolean;
}

@Component({
  selector: 'dwg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends OnDestroyMixin /*implements OnInit*/ {
  openMenuState = false;

  config$: Observable<Config> = this.store.select(selectConfigState)
    .pipe(map(config => config.data as Config));
  navData: NavData[];
  navEntries: NavEntry[];
  currentUrl: string;

  constructor(private readonly store: Store, private router: Router) {
    super();
    this.navData = [
      {
        title: 'Home',
        link: '/home',
      },
      {
        title: 'Angebote',
        link: '/angebote',
      },
      {
        title: 'Verwaltung',
        popperData: [
          {
            title: 'Hausverwaltung',
            link: 'verwaltung/hausverwaltung',
          },
          {
            title: 'WEG-Verwaltung',
            link: 'verwaltung/weg-verwaltung',
          },
          {
            title: 'Wir kaufen',
            link: 'verwaltung/wir-kaufen',
          },
        ]
      },
      {
        title: 'Kundenservice',
        link: '/kundenservice',
        popperData: [
          {
            title: 'Notdienst & Öffn.',
            link: '/kundenservice/notdienst',
          },
          {
            title: 'Ansprechpartner',
            link: '/kundenservice/ansprechpartner',
          },
          {
            title: 'Dokumente für Mieter',
            link: '/kundenservice/dokumente'
          },
          {
            title: 'Tipps für Mieter',
            link: '/kundenservice/mieter-tipps',
          },
        ],
      },
      {
        title: 'News',
        link: '/neuigkeiten-info',
      },
      {
        title: 'Unternehmen',
        popperData: [
          {
            title: 'Portrait',
            link: '/unternehmen/ueber-uns',
          },
          {
            title: 'Partner',
            link: '/unternehmen/partner',
          },
        ]
      },
      {
        title: 'Karriere',
        link: '/karriere',
      },

    ];
    this.navEntries = this.navData.map(datum => {
      return {
        ...datum,
        isOpen: true,
      };
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      untilComponentDestroyed(this)
    ).subscribe( (event: NavigationEnd) => {
      this.currentUrl = event.url;
    })
  }

  /*  ngOnInit() {
      // this.config = this.appInitService.getConfigData();
    }*/

  openMenu(): void {
    this.openMenuState = true;
  }

  closeMenu(): void {
    this.openMenuState = false;
  }

  onMobileEntryClicked(nav): void {
    // nav.isOpen = !nav.isOpen;
  }

  onMenuLinkClicked($event): void {
    this.closeMenu();
  }
}
