import { CommunicationState } from './communication.state';
import { Action, createReducer, on } from '@ngrx/store';
import { COM_PROGRESS_DECREMENT, COM_PROGRESS_INCREMENT,
  COM_PROGRESS_RESET } from './communication.actions';

const _comReducer       = createReducer (
  { progress: 0 },
  on ( COM_PROGRESS_RESET, ( state ) => ({ ...state, progress: 0 }) ),
  on ( COM_PROGRESS_INCREMENT, ( state ) => ({ ...state, progress: state.progress + 1 }) ),
  on ( COM_PROGRESS_DECREMENT, ( state ) => ({ ...state, progress: Math.max( state.progress - 1, 0 ) }) ),
);
export const comReducer = ( state: CommunicationState, action: Action ) => _comReducer ( state, action );
