import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {NEWS_LOAD, selectNews} from '@ed-clients/common-store';

import {BlackbirdPost} from './blackbird.interface';
import {resolvePosts} from './resolve-posts.fnc';

// Note: use postCfg to set pagination whenever lazy-loading is needed

@Injectable( {
  providedIn: 'root'
} )
export class PostsResolver implements Resolve<BlackbirdPost[]> {

  constructor(
    protected store: Store
  ) {
  }

  // return empty array if no news available
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BlackbirdPost[]> {
    return resolvePosts(this.store, selectNews, NEWS_LOAD, route, state);
  }
}
