import { InjectionToken } from '@angular/core';

export interface ClientEnvironment {
  ssr: {
    shopHost: string;
    jsonHost: string,
  };
  browser: {
    host: string;
    shopHost: string;
    jsonHost: string
  };
  blackbird: {
    projectSlug: string;
    content: string; // for static pages and also config data
    posts: string;
    token: string;
  };
  shopware: {
    products: string;
    categories?: string,
    accessKey?: string; // accessKey for item-products, it is optional
    realEstatesAccessKey?: string;
    jobsAccessKey?: string,
    referencesAccessKey?: string,
    vendorId: string;
    referencesCategoryId?: string,
  }
  join: {
    host: string;
    accessToken: string;
    debug: boolean;
  }
}

export const ENVIRONMENT: InjectionToken<string> = new InjectionToken<ClientEnvironment> ( 'Environment of Premium Client' );

export const INTERNAL_LIB_ERROR_FEEDBACK_MAIL: InjectionToken<string> =
  new InjectionToken<string> ( 'mail address that receives errors' );
