import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Reference, ReferencesCategory } from './project-references.interface';

export const referencesAdapter: EntityAdapter<Reference> = createEntityAdapter<Reference> ( {
    selectId: reference => reference.id,
    sortComparer: false
  }
);

export interface ReferencesState extends EntityState<Reference> {
  categories?: ReferencesCategory[];
  total?: number;
}
