import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { CommonForm, DynamicFormItem } from './common-form.interface';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'ed-clients-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.scss']
})
export class CommonFormComponent<FormValInterface> implements OnInit, OnDestroy {
  Number = Number;

  @Input () hideRequiredMark = true;
  @Input () inputFormFieldStyle = 'outline';

  @Output ()
  send: EventEmitter<FormValInterface>        = new EventEmitter<FormValInterface> ();
  @Output ()
  formUpdated: EventEmitter<FormValInterface> = new EventEmitter<FormValInterface> ();
  @Output ()
  getFormGroup: EventEmitter<FormGroup> = new EventEmitter<FormGroup> ();

  @Input ()
  dynamicFormData: CommonForm;
  formGroup: FormGroup;

  @Input() useDisabledButton = true;

  @ViewChild('myForm') myForm: NgForm;

  private _formValues: FormValInterface;

  displaySend = 'block';
  displayReset = 'block';

  get formValues (): FormValInterface {
    return this._formValues;
  }

  @Input ()
  set formValues  ( value: FormValInterface ) {
    if ( value ) {
      this.setFormValues ();
    }
    this._formValues = value;
  }

  private subs: Subscription = new Subscription ();

  sendData () {
    this.send.emit ( this.formGroup.value );
  }

  ngOnInit (): void {
    this.createFormGroupFromData ();
    this.setFormValues ();
    this.getFormGroup.emit ( this.formGroup ); //initial status of validity
    this.subs.add (
      this.formGroup.statusChanges.subscribe (
        status => this.getFormGroup.emit ( this.formGroup )
      )
    )
    this.subs.add (
      this.formGroup.valueChanges.subscribe (
        v => this.formUpdated.emit ( v )
      )
    )
  }

  ngOnDestroy (): void {
    this.subs.unsubscribe ();
  }

  getIcon ( item: DynamicFormItem ) {
    return item.type === 'password' ? 'remove_red_eye' : 'password';
  }

  private setFormValues () {
    if ( this.formGroup && this._formValues ) {
      this.formGroup.patchValue ( this._formValues );
      this.formGroup.markAsDirty ();
    }
  }

  togglePwdType ( item: DynamicFormItem ) {
    item.type = item.type === 'password' ? 'text' : 'password';
  }

  triggerValidation ( invalid: boolean ) {
    if ( invalid ) {
      this.formGroup.markAllAsTouched ();
    }
  }

  resetForm() {
    this.myForm.resetForm()
  }

  private createFormGroupFromData () {
    this.formGroup = new FormGroup ( {} );

    if( this.dynamicFormData.resetLabel === 'none' ) {
      this.displayReset = this.dynamicFormData.resetLabel;
    }

    if( this.dynamicFormData.sendLabel === 'none' ) {
      this.displaySend = this.dynamicFormData.sendLabel;
    }

    this.dynamicFormData.items.forEach ( item => {
      const validatorFunctions = item.validators || [];
      const required           = validatorFunctions.indexOf ( Validators.required ) !== - 1;
      if ( item.required !== true ) {
        item.required = required;
      }
      if ( item.isPassword && item.type !== 'password' ) {
        item.type = 'password';
      } else if ( !item.isPassword && item.type === 'password' ) {
        item.isPassword = true;
      }
      const ctrl = new FormControl ( undefined, validatorFunctions );
      /**
       * workaround weil ich FormControl Value inital nicht setzen kann ohne
       * LifeCylice fehler
       */
      if ( item.value ) {
        timer ( 0 )
          .subscribe ( v => {
            ctrl.setValue ( item.value );
          } );
      }
      this.formGroup.addControl ( item.key, ctrl );
      item.formControl = ctrl;
    } );
  }
}
