import { BlackbirdPagination, BlackbirdPost } from '@ed-clients/shared';
import { createAction, props } from '@ngrx/store';

export const FAQ_LOAD = createAction ( '[FAQ] LOAD FAQ',
  props<{ page?: number, limit?: number, query?: string }> ()
);

export const FAQ_SET = createAction ( '[FAQ] SET FAQ',
  props<{ faq: BlackbirdPost[] }> ()
);

export const FAQ_LAZY_LOADED_SET = createAction ( '[FAQ] LAZY LOADED SET FAQ',
  props<{ faq: BlackbirdPost[] }> ()
);

export const FAQ_SET_Pagination = createAction ( '[FAQ] SET FAQ PAGINATION',
  props<{ pagination: BlackbirdPagination }> ()
);
