import {HttpClientModule} from '@angular/common/http';
import '@angular/common/locales/global/de';
import {LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  JobsStoreModule, NewsStoreModule,
} from '@ed-clients/common-store';
import {
  BlackbirdInterceptorModule,
  ENVIRONMENT,
  GermanTelOrFaxPipeModule,
  LazyPictureModule, MatDialogErrorHandlerModule
} from '@ed-clients/shared';
import {
  COM_PROGRESS_DECREMENT,
  COM_PROGRESS_INCREMENT,
  CommunicationStoreModule,
  ConfigStoreModule,
  ErrorModule,
  RouterStoreModule
} from '@nettrek-angular-libs/common-store';
import {SsrModule} from '@nettrek-angular-libs/ssr';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {SvgSpritesComponent} from './shared-across-dwg-only/svg-sprites/svg-sprites.component';
import {ShorthandIconsModule} from "./shared-across-dwg-only/shorthand-icons/shorthand-icons.module";

console.log(environment.browser.jsonHost + environment.blackbird.config);

@NgModule({
  declarations: [
    AppComponent,
    SvgSpritesComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    AppRoutingModule,
    SsrModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    ConfigStoreModule.forRoot(environment.browser.jsonHost + environment.blackbird.config),
    EffectsModule.forRoot(),
    RouterStoreModule, // falls Router Store Benötigt
    CommunicationStoreModule, // Falls Kommunikations Stand benötigt,
    JobsStoreModule,
    NewsStoreModule,
    ErrorModule.forRoot({
      allowOverridingGlobalErrorHandler: true,
      // Standart-Klasse die das darstellen des Fehlers und den RESET des Fehlers Steuert
      // showErrHandler: ShowErrorHandler,
      errorFeedbackMail: 'info@netTrek.de',
      // ignoreHttpErrForThisUrls: [
      //   // 'assets/mock/data.jsosn' // for Testing Only
      // ],
      // ignoreHttpStatusCodes: [ 404 ]

    }),
    MatDialogErrorHandlerModule,
    ...(environment.useReduxDevTools ? [
      StoreDevtoolsModule.instrument({
        maxAge: 100, logOnly: !environment.useReduxDevTools,
        // wenn bestimmte Aktionen im Dev Tool nicht angezeigt werden sollen
        actionsBlocklist: [COM_PROGRESS_INCREMENT.type,
          COM_PROGRESS_DECREMENT.type,
        ],
      }),
    ] : []),
    BlackbirdInterceptorModule.forRoot(environment.browser.jsonHost, environment.blackbird.token),
    HttpClientModule,
    LazyPictureModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxJsonLdModule,
    GermanTelOrFaxPipeModule,
    ShorthandIconsModule,
  ],
  providers: [
    {
      provide: LOCALE_ID, // currency pipe
      useValue: 'de-DE',
    },
    {
      provide: ENVIRONMENT, useValue: environment,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
