import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {BlackbirdPost} from '@ed-clients/shared';
import {filter, take, tap} from 'rxjs/operators';
import {ActionCreator, DefaultProjectorFn, MemoizedSelector, Store} from '@ngrx/store';
import {TypedAction} from '@ngrx/store/src/models';

export function resolvePosts(store: Store,
                             // eslint-disable-next-line @typescript-eslint/ban-types
                             storeSelectFn: MemoizedSelector<object, BlackbirdPost[], DefaultProjectorFn<BlackbirdPost[]>>,
                             storeDispatchFn: ActionCreator<any, (props: { page?: number; limit?: number; query?: string; }) => ({
    page?: number; limit?: number; query?: string;
  } & TypedAction<any>)>,
                             route: ActivatedRouteSnapshot,
                             state: RouterStateSnapshot): Observable<BlackbirdPost[]> {

  let count = 0; // this boolean prevents the store from dispatching again and again when there are no news in Blackbird
  let loaded = false; // this count prevents the store from dispatching again and again when there are no news in Blackbird

  return store.select(storeSelectFn)
      .pipe(
        tap((news: BlackbirdPost[]) => {
          count++; // count = 2 means loaded
          if (news.length === 0 && !loaded && count < 2) {
            const {page, limit, query} = route.data.postCfg || {};
            store.dispatch(storeDispatchFn({page, limit, query}));
          } else {
            loaded = true;
          }
        }),
        filter(() => loaded),
        take(1)
      );
}
