import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { selectConfigState } from '@nettrek-angular-libs/common-store';
import { filter, map, take, tap } from 'rxjs/operators';
import { BlackbirdConfig } from './blackbird.interface';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ConfigDataResolver implements Resolve<BlackbirdConfig> {
  constructor (private readonly store: Store) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BlackbirdConfig> {
    return this.store.select ( selectConfigState ).pipe (
      filter ( config => !!config && Object.keys(config).length > 0 ), // metaInfoService runs before app initializer, thus, filtering is needed
      map ( config => config.data as BlackbirdConfig ),
      take(1),
    )
    // return of(true);
  }
}
