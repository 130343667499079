import { createFeatureSelector, createSelector } from '@ngrx/store';
import { newsAdapter, NewsState } from './news.state';

export const newsFeatureKey  = 'news';
export const selectNewsState = createFeatureSelector<NewsState> ( newsFeatureKey );

export const selectNews = createSelector (
    selectNewsState,
    newsAdapter.getSelectors ().selectAll,
);

export const selectNewsPagination = createSelector (
    selectNewsState,
    ( state: NewsState ) => state.pagination,
);

export const selectNewsByPage = createSelector (
    selectNews,
    ( news, page ) => {
        return news.filter ( post => post.page === page );
    },
);

export const selectNewsBySlug = createSelector (
    selectNews,
    ( news, slug ) => {
        return news.filter ( post => post.slug === slug );
    },
);
