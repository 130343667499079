import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { JoinJob } from '@ed-clients/common-store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CombineMetasService } from './combine-metas.service';
import { MetaInformationService } from './meta-information.service';
import { MetaData } from './meta.interface';

@Injectable ( {
  providedIn: 'root'
} )
export class MetaServiceResolver implements Resolve<Record<string, any>> {
  joinSchemaCompanyData: {
    name: string;
    url: string;
    address: {
      street: string;
      postcode: string;
      city: string;
      region: string;
      country: string;
    }
  } | undefined;
  corporateLogo: string | undefined; // only for JOIN

  constructor ( private readonly injector: Injector,
                private readonly combineMetas: CombineMetasService,
                private readonly metasService: MetaInformationService
  ) {
  }

  resolve ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Record<string, any>> {
    if ( route.data.deps && Object.keys ( route.data.deps ).length > 0 ) {
      this.joinSchemaCompanyData = route.data.joinJobCompanyData; // undefined if no JOIN data is used

      const realResolver: Record<string, Resolve<any>> = {};
      /* Erstellen aller Resolver, die in den deps der Routendaten angegeben sind */
      Object.keys ( route.data.deps )
            .forEach ( value => {
              realResolver[ value ] = this.injector.get ( route.data.deps[ value ] );
            } );
      /* Mergen der Ergebnisse aller Resolver und anschließendes Kombinieren der Daten durch den CombineMetasService */
      return forkJoin ( Object.keys ( realResolver )
                              .map ( value => realResolver[ value ].resolve ( route, state ) ) )
        .pipe (
          map ( v => {
            const resolver: Record<string, any> = {};
            Object.keys ( realResolver )
                  .forEach ( ( value, index ) => {
                    resolver[ value ] = v[ index ];
                  } );
            return resolver;
          } ),
          tap ( resolved => {
            const mainMeta                    = this.getMainMeta ( route.data.metaCombination.mainMeta, resolved );
            this.corporateLogo = mainMeta.seo.image; // only for JOIN schema usage
            const blackbirdMeta               = this.getMetaInformations ( route.data.metaCombination.blackbird, resolved );
            const shopwareMeta                = this.getMetaInformations ( route.data.metaCombination.shopware, resolved );
            const joinMeta                = this.getMetaInformations ( route.data.metaCombination.join, resolved );
            const includeBlackbirdSchemasOnly = route.data.metaCombination.includeBlackbirdSchemasOnly || false;
            const includeShopwareSchemasOnly  = route.data.metaCombination.includeShopwareSchemasOnly || false;
            const metas                       = this.combineMetas.combineMetas ( {
              mainMeta,
              blackbirdMeta,
              shopwareMeta,
              joinMeta,
              includeBlackbirdSchemasOnly,
              includeShopwareSchemasOnly
            } );
            /* Übergabe der Metadaten an den metasService, der diese in die Seite schreibt */
            this.metasService.updateMetaInfos ( metas, this.getResolvedUrl(route) );
            console.log ('raw', resolved);
            console.log('metas', metas);
          } ),
          take ( 1 )
        );
    } else {
      return of ( {} );
    }

  }

  private getMetaDataOfObj ( obj: Record<string, any> ): MetaData {
    const { seo, socialMedia, schemas } = obj;
    if(!seo && !socialMedia && !schemas && obj.customFields && obj.customFields.ed_cf_seo_social_media) {
      const swData = JSON.parse(obj.customFields.ed_cf_seo_social_media);
      // console.log('SW DATA', obj);
      // console.log('SW SEO', swData);
      return {
        seo: swData.seo,
        socialMedia: swData.socialMedia,
        schemas: swData.schema ? [JSON.parse(swData.schema)] : swData.schemas
      };
    }

    // for JOIN job
    if(!seo && !socialMedia && !schemas && this.joinSchemaCompanyData) {
      const joinJob = obj as JoinJob;
      return {
        seo: {
          title: joinJob.title,
          description: joinJob.description,
          image: ''
        },
        socialMedia: {
          title: joinJob.title,
          description: joinJob.description,
          image: '',
          twitterId: null
        },
        schemas: [this.getJoinJobSchema(joinJob)]
      }
    }

    return { seo, socialMedia, schemas };
  }

  private getMainMeta ( key, resolved: Record<string, any> ) {
    return key ? this.getMetaData ( resolved[ key ] ) [0] : {};
  }

  private getMetaData ( records: Record<string, any> | Record<string, any> [] ) {
    const result: MetaData [] = [];
    if ( records ) {
      if ( Array.isArray ( records ) ) {
        records.forEach ( record => result.push ( this.getMetaDataOfObj ( record ) ) );
      } else {
        result.push ( this.getMetaDataOfObj ( records ) );
      }
    }
    return result;
  }

  private getMetaInformations ( resolvedKeys: string[], resolved: Record<string, any> ): Record<string, any>[] {
    let result = [];
    if ( resolvedKeys ) {
      resolvedKeys.forEach ( key => {
        result = result.concat ( this.getMetaData ( resolved[ key ] ) );
      } );
    }
    return result;
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.filter(segment => segment.path.length > 0)
                     .map(segment => segment.toString()).join('/')
      )
      .filter(segment => segment.length > 0)
      .join('/');
  }

  private getJoinJobSchema(joinJob: JoinJob) {
    const defaultExpiryDateOfJob = new Date();
    defaultExpiryDateOfJob.setFullYear(defaultExpiryDateOfJob.getFullYear() + 1)
    
    return {
      "@context": "https://schema.org",
      "@type": "JobPosting",
      "datePosted": joinJob.createdAt,
      "validThrough": joinJob.deadline? joinJob.deadline : defaultExpiryDateOfJob.toISOString(),
      "title": joinJob.title,
      "description": this.metasService.stripHtml(joinJob.description),
      "image": this.corporateLogo,
      "employmentType": joinJob.contract?.toLowerCase().includes('part')? 'PART_TIME': 'FULL_TIME',
      "hiringOrganization": {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": this.joinSchemaCompanyData?.name? this.joinSchemaCompanyData.name : '',
        "url": this.joinSchemaCompanyData?.url? this.joinSchemaCompanyData.url : '',
        "logo": this.corporateLogo,
        "address": this.joinSchemaCompanyData?.address? {
          "@type": "PostalAddress",
          "postalCode": this.joinSchemaCompanyData.address.postcode,
          "streetAddress": this.joinSchemaCompanyData.address.street,
          "addressCountry": this.joinSchemaCompanyData.address.country,
          "addressRegion": this.joinSchemaCompanyData.address.region,
          "addressLocality": this.joinSchemaCompanyData.address.city
        } : '',
        "sameAs": this.joinSchemaCompanyData?.url? this.joinSchemaCompanyData.url : ''
      },
      "jobLocation":{
        "@type": "Place",
        "address":{
          "@type": "PostalAddress",
          "postalCode": this.joinSchemaCompanyData.address.postcode,
          "streetAddress": this.joinSchemaCompanyData.address.street,
          "addressRegion": this.joinSchemaCompanyData.address.region,
          "addressCountry": joinJob.location.countryIso? joinJob.location.countryIso : this.joinSchemaCompanyData.address.country,
          "addressLocality": joinJob.location.city? joinJob.location.city : this.joinSchemaCompanyData.address.city
        }
      },
      "baseSalary": {
        "@type": "MonetaryAmount",
        "currency": joinJob.salary.currency? joinJob.salary.currency : 'EUR',
        "value": {
          "@type": "QuantitativeValue",
          "value": "1",
          "unitText": joinJob.salary.frequency,
          "minValue": joinJob.salary.isShownOnJobAd? joinJob.salary.from : '',
          "maxValue": joinJob.salary.isShownOnJobAd? joinJob.salary.to : ''
        }
      }
    }
  }
}

