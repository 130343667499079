import { createAction, props } from '@ngrx/store';
import { JoinApplicationForm, JoinJob } from './jobs-join.interface';

export const JOBS_JOIN_LOAD = createAction ( '[JOIN JOBS] LOAD JOIN JOBS' );

export const JOBS_JOIN_SET = createAction ( '[JOIN JOBS] SET JOIN JOBS',
  props<{ jobs: JoinJob[] }> ()
);

export const JOBS_JOIN_APPLICATION = createAction ( '[JOIN JOBS] APPLY JOIN JOBS',
  props<{ form: JoinApplicationForm, otherFiles: File[], id: number }> ()
);
