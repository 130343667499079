import { RootStoreState } from '../root-state';
import { RouterReducerState } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

import { RouterStateUrl } from './custom-serializer';

export const selectRouterState = (state: RootStoreState) => state.router;

export const selectRouter = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>) => state.state
);

// Note: The getSelectors method (built-in selectors) works with the routerReducer provided by @ngrx/router-store.
// If you use a custom serializer, you'll need to provide your own selectors.

export const selectPreviousUrl = createSelector(
  selectRouter,
  ( router: RouterStateUrl ) => router.previousUrl
)

export const selectUrl = createSelector(
  selectRouter,
  ( router: RouterStateUrl ) => router.url
)

export const selectRouteData = createSelector(
  selectRouter,
  ( router: RouterStateUrl ) => router.data
)

export const selectRouteParams = createSelector(
  selectRouter,
  ( router: RouterStateUrl ) => router.params
)

export const selectQueryParams = createSelector(
  selectRouter,
  ( router: RouterStateUrl ) => router.queryParams
)
