import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NewsEffectsService } from './news.effects.service';
import { newsReducer } from './news.reducer';
import { newsFeatureKey } from './news.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature ( newsFeatureKey, newsReducer ),
    EffectsModule.forFeature ( [NewsEffectsService] ),
  ]
})
export class NewsStoreModule { }
