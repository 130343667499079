import { createAction, props } from '@ngrx/store';
import { ErrorCodeDetails } from '../error-codes';

export const ERROR_SET =
               createAction ( '[ERROR] SET',
                 props<{ active: ErrorCodeDetails | undefined }> () );

export const ERROR_RESET = createAction ( '[ERROR] RESET' );

export const ERROR_SET_IS_FATAL =
               createAction ( '[ERROR] SET IS FATAL',
                 props<{ isFatal: boolean | undefined }> () );
