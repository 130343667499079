import { Action, createReducer, on } from '@ngrx/store';
import { NEWS_LAZY_LOADED_SET, NEWS_SET, NEWS_SET_Pagination } from './news.actions';
import { initialNewsState, newsAdapter, NewsState } from './news.state';

const _newsReducer = createReducer (
  initialNewsState,
  on ( NEWS_SET, ( state, { news } ) => newsAdapter.setAll ( news, state ) ),
  on ( NEWS_LAZY_LOADED_SET, ( state, { news } ) => newsAdapter.addMany ( news, state ) ),
  on( NEWS_SET_Pagination, ( state, { pagination } ) =>
    ( { ...state, pagination } )
  )
);

export const newsReducer = ( state: NewsState, action: Action ) => _newsReducer ( state, action );
