import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {selectConfigState} from '@nettrek-angular-libs/common-store';

import {Config} from '../app-init.interface';
import {FooterLink} from './footer.interface';

@Component({
  selector: 'dwg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent /*implements OnInit*/ {
  config$: Observable<Config> = this.store.select(selectConfigState).pipe(map(config => config.data as Config));
  footerLinks: FooterLink[];

  constructor(private readonly store: Store) {
    this.footerLinks = [
      {
        name: 'Kontakt',
        link: '/kontakt'
      },
      {
        name: 'Impressum',
        link: '/impressum'
      },
      {
        name: 'Datenschutz',
        link: '/datenschutz'
      }
    ];
  }

  /*
    ngOnInit() {
      this.config = this.appInitService.getConfigData();

    }*/

}
