<div class="header content-container" [ngClass]="{ 'is-menu-open': openMenuState }">
  <div class="header-head">
    <a class="logo" [routerLink]="'/'">
      <ed-clients-lazy-bb-picture *ngIf='config$ | async as config'
                               class="logo-image contain" width="151" height="31"
                               [blackbirdImage]="config.logo"
      ></ed-clients-lazy-bb-picture>
    </a>

    <nav class="main-navigation">
      <ul class="main-navigation-entries">
        <li class="main-navigation-entry" *ngFor="let nav of navEntries">
          <ng-container *ngIf="nav.popperData; then withPopperData else noPopperData"></ng-container>

          <ng-template #withPopperData>
            <button
                title="Zur Seite {{ nav.title }} wechseln."
                class="main-navigation-entry-link-with-popper">
              <a class="popper-navigation-text linked" *ngIf="nav.link; else notLink" [routerLink]="nav.link" routerLinkActive="active">{{nav.title}}<mat-icon>arrow_drop_down</mat-icon></a>
              <ng-template #notLink>
                <a class="popper-navigation-text" [class.not-linked-entry-active]="currentUrl?.includes(nav.title.toLowerCase())">{{nav.title}}<mat-icon>arrow_drop_down</mat-icon></a>
              </ng-template>

              <div class="popper-container">
                <ul class="main-navigation-sub-entries">
                  <li class="main-navigation-sub-entry" *ngFor="let subNav of nav.popperData">
                    <a class="main-navigation-sub-entry-link"
                       [routerLink]="subNav.link"
                       routerLinkActive="active"
                    >{{subNav.title}}</a>
                  </li>
                </ul>
              </div>
            </button>
          </ng-template>

          <ng-template #noPopperData>
            <a class="main-navigation-entry-link"
               [href]="nav.link"
               [routerLink]="nav.link"
               routerLinkActive="active"
            >{{nav.title}}</a>
          </ng-template>
        </li>
      </ul>
    </nav>

    <ng-container *ngIf="openMenuState; then closeMobileButton else openMobileButton"></ng-container>

    <ng-template #closeMobileButton>
      <button title="Navigation ausblenden." class="mobile-navigation-trigger mobile-navigation-trigger-close"
              (click)="closeMenu()">
        <svg class="mobile-navigation-trigger-icon">
          <use href="#dwg-icon-close"></use>
        </svg>
      </button>
    </ng-template>

    <ng-template #openMobileButton>
      <button title="Navigation einblenden." class="mobile-navigation-trigger mobile-navigation-trigger-open"
              (click)="openMenu()">
        <svg class="mobile-navigation-trigger-icon">
          <use href="#dwg-icon-menu"></use>
        </svg>
      </button>
    </ng-template>
  </div>

  <div *ngIf="openMenuState" class="main-navigation-mobile-content">
    <nav class="main-navigation-mobile">
      <ul class="main-navigation-mobile-entries">
        <li class="main-navigation-mobile-entry" *ngFor="let nav of navEntries">
          <ng-container *ngIf="nav.popperData; then withPopperData else noPopperData"></ng-container>

          <ng-template #withPopperData>
            <button *ngIf="nav.link; else notLink" title="Zur Seite {{ nav.title }} wechseln." class="main-navigation-mobile-entry-link"
                    (click)="onMenuLinkClicked($event)" [routerLink]="nav.link" routerLinkActive="active">{{nav.title}}</button>
            <ng-template #notLink>
              <div title="Zur Seite {{ nav.title }} wechseln." class="main-navigation-mobile-entry-no-link"
                      (click)="onMobileEntryClicked(nav)" >{{nav.title}}</div>
            </ng-template>

            <ul class="main-navigation-mobile-sub-entries" [ngClass]="{ 'is-open': nav.isOpen }">
              <li class="main-navigation-mobile-sub-entry" *ngFor="let subNav of nav.popperData">
                <a class="main-navigation-mobile-sub-entry-link"
                   (click)="onMenuLinkClicked($event)"
                   [href]="subNav.link"
                   [routerLink]="subNav.link"
                   routerLinkActive="active">{{subNav.title}}</a>
              </li>
            </ul>
          </ng-template>

          <ng-template #noPopperData>
            <a class="main-navigation-mobile-entry-link"
               (click)="onMenuLinkClicked($event)"
               [href]="nav.link"
               [routerLink]="nav.link"
               routerLinkActive="active">{{nav.title}}</a>
          </ng-template>
        </li>
      </ul>
    </nav>
  </div>
</div>
