import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Job } from './jobs.interface';
import { jobsAdapter } from './jobs.state';

export const jobsFeatureKey  = 'jobs';
export const selectJobsState = createFeatureSelector<EntityState<Job>> ( jobsFeatureKey );

export const selectJobs = createSelector (
  selectJobsState,
  jobsAdapter.getSelectors().selectAll
);
