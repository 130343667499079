// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BlackbirdPagination, BlackbirdPost } from '@ed-clients/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const videosAdapter: EntityAdapter<BlackbirdPost> = createEntityAdapter<BlackbirdPost> ( {
    selectId: post => post.id, // slug works fine too
    sortComparer: (a, b): number => Number(new Date(b.published)) - Number(new Date(a.published))
    // sorting takes place when using selector. It is not sorted on dev tool view
  }
);

export interface VideosState extends EntityState<BlackbirdPost> {
  pagination: BlackbirdPagination
}

export const initialVideosState: VideosState = videosAdapter.getInitialState({
  pagination: undefined
});
