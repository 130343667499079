import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

const BLACKCIRD_HOST = new InjectionToken<string>('Blackbird Host' );
const BLACKCIRD_TOKEN = new InjectionToken<string>('Blackbird Token' );

class AppBlackbirdInjector implements HttpInterceptor {

  constructor ( private readonly host: string, private readonly token: string ) {
  }

  intercept ( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any> | undefined;

    if ( req.url.indexOf( this.host ) !== -1 ) {

      newReq = req.clone( {
        headers: req.headers.set( 'Authorization', 'Bearer ' + this.token ).
        set( 'Accept', 'application/json' )
      });
    }

    return next.handle( newReq??req );
  }

}

@NgModule({})
export class BlackbirdInterceptorModule {
  static forRoot ( host: string, token: string ): ModuleWithProviders<BlackbirdInterceptorModule> {
    return {
      ngModule: BlackbirdInterceptorModule,
      providers: [
        { provide: BLACKCIRD_HOST, useValue: host },
        { provide: BLACKCIRD_TOKEN, useValue: token },
        {provide: HTTP_INTERCEPTORS, useClass: AppBlackbirdInjector, multi: true, deps: [
          BLACKCIRD_HOST, BLACKCIRD_TOKEN
          ]  }
      ]
    }
  }
}
