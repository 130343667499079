import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class IntersectionObserverService {

  readonly intersect: IntersectionObserver;

  private observedElements: Map<Element, () => void> = new Map<Element, () => void>();
  private initalRender = true;

  constructor(
    @Inject( PLATFORM_ID ) private pltID: string
  ) {
    if ( !isPlatformServer( this.pltID ) ) {
      this.intersect = new IntersectionObserver( ( entries => {
        for ( const entry of entries ) {
          const callback = this.observedElements.get( entry.target );
          if ( entry.target && entry.isIntersecting ) {
            callback();
            this.remove( entry.target );
          }
        }
      } ), {
        rootMargin: '50px',
        threshold: 0.001
      } );
    }
  }

  add( element: Element, callback: () => void, firstNotLazy: boolean) {
    if ( this.intersect && !(firstNotLazy && this.initalRender) ) {
      this.observedElements.set( element, callback );
      this.intersect.observe( element );
    } else {
      callback();
    }
  }

  remove( element: Element ) {
    if(this.observedElements.delete( element )) {
      this.intersect.unobserve( element );
    }
  }

  initalRenderFinished(){
    this.initalRender = false;
  }
}
