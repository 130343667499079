import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { take } from 'rxjs/operators';
import { DynamicComponent } from './dynamic-component.interface';
import { DynamicComponentsRenderingDirective } from './dynamic-components-rendering.directive';
import { RenderingItem } from './rendering-item';

// doesnt support 1.5 components rendering, support only whole numbers amount of components

@Component({
  selector: 'ed-clients-dynamic-components-rendering',
  templateUrl: './dynamic-components-rendering.component.html',
  styleUrls: ['./dynamic-components-rendering.component.scss']
})
export class DynamicComponentsRenderingComponent<T> implements OnChanges {
  @Input() renderingItems: RenderingItem<T>[];
  currentIndex = -1;
  @ViewChild(DynamicComponentsRenderingDirective, {static: true}) dynamicRenderingDirective: DynamicComponentsRenderingDirective;
  previousComponentRef: ComponentRef<DynamicComponent<T>>;

  constructor( private componentFactoryResolver: ComponentFactoryResolver ) { }

  ngOnChanges(): void {
    if( this.renderingItems ) { // if resolver is not used and API Call is made OnInit, it is initially undefined
      this.loadComponent();
    }
  }

  loadComponent(moveBack= false) {
    if( moveBack ) {
      if( this.currentIndex === 0 ) {
        this.currentIndex = this.renderingItems.length - 1; //last index
      } else {
        this.currentIndex = (this.currentIndex - 1) % this.renderingItems.length;
      }
    } else {
      this.currentIndex = (this.currentIndex + 1) % this.renderingItems.length;
    }

    this.renderViewByIndex( this.currentIndex );
  }

  renderViewByIndex(index: number): void {
    const renderingItem = this.renderingItems[ index ];

    if (renderingItem?.component) {

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(renderingItem.component);

      const viewContainerRef = this.dynamicRenderingDirective.viewContainerRef;

      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<DynamicComponent<T>>(componentFactory);

      this.previousComponentRef = componentRef;

      componentRef.instance.data = renderingItem.data;
    }
  }

  // use this only when sliding animation is intended
  animateDynamicComponents(moveBack= false) {
    if( moveBack ) {
      this.previousComponentRef.instance.visibility = 'hiddenRight';
    } else {
      this.previousComponentRef.instance.visibility = 'hiddenLeft';
    }

    this.previousComponentRef.instance.animationEnd.pipe(
      take(1)
    ).subscribe(() => {
      this.loadComponent(moveBack);
      this.previousComponentRef.instance.slideForward = !moveBack;
    });
  }

}

// Sliding animation references:
// https://angular.io/guide/animations
// https://netbasal.com/animate-dynamic-components-in-angular-10681438bdd4
// https://medium.com/@asm/animated-slide-panel-with-angular-e985ad646f9
