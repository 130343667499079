import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { JOBS_LOAD, JOBS_SET } from './jobs.actions';
import { JobsService } from './jobs.service';

@Injectable()
export class JobsEffectsService {

  constructor(
    private actions: Actions,
    private jobsService: JobsService
  ) { }

  loadJobs = createEffect( () => this.actions.pipe(
    ofType( JOBS_LOAD ),
    mergeMap( () => this.jobsService.getJobs().pipe(
      map( ( res ) => {
        return JOBS_SET({jobs:res});
      } )
    ) )
  ), { dispatch: true } );
}
