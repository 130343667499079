import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { LightWeightDialogModule } from '../light-weight-dialog/light-weight-dialog.module';
import { NettrekGoogleMapComponent } from './nettrek-google-map.component';
import { GoogleMapConsentDialogComponent } from './google-map-consent-dialog/google-map-consent-dialog.component';



@NgModule({
  declarations: [
    NettrekGoogleMapComponent,
    GoogleMapConsentDialogComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    LightWeightDialogModule
  ],
  exports: [
    NettrekGoogleMapComponent
  ]
})
export class NettrekGoogleMapModule { }
