import { ERROR_RESET, ErrorCodeDetails } from '@nettrek-angular-libs/common-store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ErrorDialogComponent } from './error-mat-dialog/error-dialog.component';

export class AppErrorHandler {

  constructor ( private readonly store: Store,
                private readonly dialog: MatDialog ) {
  }

  private dialogRef?: MatDialogRef<ErrorDialogComponent, any>;

  show ( error: ErrorCodeDetails ) {
    this.dialogRef = this.dialog.open( ErrorDialogComponent, {
      minWidth: '50%', maxWidth: '95%', maxHeight: '480px', data: error, disableClose: true
    } )

    this.dialogRef.afterClosed ()
        .subscribe ( result => {
          this.close()
        } );
  }

  close () {
     this.store.dispatch( ERROR_RESET() );
  }
}
