import { Inject, Injectable } from '@angular/core';
import { ClientEnvironment, ENVIRONMENT } from '../common-injection-token';
import { MetaData, Schema, Seo, SocialMedia } from './meta.interface';

interface MetaDataProperty extends Omit<MetaData, 'schemas'> {
  schemas?: Schema | Schema[];
}

/* Begrenzt die Länge eines Strings auf 160 Zeichen (bzw. 157 mit ...) */
const trim = ( str: string, length = 160 ) => str.length > length - 3 ?
  str.substring( 0, length - 3 ) + '...' :
  str;

/* Entfernt alle HTML Inhalte */
const stripHtml = (html: string): string => html?.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '') || '';

/* Überprüft, ob es sich bei den übergebenen Daten um die Keys "Description" oder "Title" handelt und entfernt entsprechend die HTML Inhalte */
const cleanDescAndTitle = ( value: any, key: 'description' | 'title' | any  ) =>
  value && typeof value === 'string' && key && typeof key === 'string' && (key === 'description' || key === 'title')
    ? trim(stripHtml(value))
    : value;

/* Überprüft, ob es sich um ein Element eines nativen Typs handelt (string / number / boolean) */
const isNativeType = ( value: any ) => typeof value === 'string' ||
                                       typeof value === 'number' ||
                                       typeof value === 'boolean';

function createUrlFromObject(value, environment: ClientEnvironment) {
  if(value && typeof value === 'object' && value.dir !== undefined && value.dir !== null) {
    return environment.browser.jsonHost + value.dir.replace('webroot/', '') + value.filename;
  } else {
    return value;
  }
}

/* Entfernt rekursiv Elemente mit leerem, null oder undefined Value */
function removeEmpty ( obj, environment: ClientEnvironment ) {
  return Object.entries ( obj )
               .filter ( ( [k, v] ) => {

                 const isNotEmptyString = typeof v === 'string' /* && k.trim().toLowerCase() !== 'url' */ ?  v.trim().length > 0 : true ;
                 const isNotNull = v !== null && v !== undefined;
                 let isNotEmptyArray = true;

                 if (isNotNull) {
                   isNotEmptyArray = Array.isArray(v)
                     ? v.length > 0
                     : true;
                 }

                 return (isNotEmptyString && isNotNull);
               })
               .reduce (
                 ( acc, [k, v] ) => {
                   if ( Array.isArray( v ) ) {
                     v = v.map( value => isNativeType (value) ? value : removeEmpty( value, environment ) );
                   } else if ( v === Object ( v ) ) {
                     v = removeEmpty( v, environment );
                     v = createUrlFromObject( v, environment );
                   } else {
                     v = cleanDescAndTitle( v, k )
                   }
                   return {...acc, [k]: v };
                   // ({ ...acc, [ k ]: v === Object ( v ) && !Array.isArray ( v ) ? removeEmpty ( v ) : cleanDescAndTitle ( v, k ) });
                 },
                 {}
               );
}

@Injectable ( {
  providedIn: 'root'
} )
export class CombineMetasService {

  /* Blacklist, welche Schema-Typen entfernt werden, wenn sie doppelt auftreten */
  private metaDoubleBlacklist = [
    'JobPosting'
  ];

  /* Blacklist, welche Schema-Typen nur ein einziges Mal auftreten dürfen */
  private metaOnlySingleBlacklist = [
    'Website',
    'Organization'
  ];

  private mockMain: MetaDataProperty = {
    schemas    : {
      '@context': 'mainContext',
      '@type'   : 'mainType'
    },
    seo        : {
      title      : 'mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle mainSeoTitle',
      description: '<b>mainSeoDescription</b>'
    },
    socialMedia: {
      title      : 'mainSocialMediaTest',
      description: 'mainSocialMediaDescription',
      image      : '',
      twitterId  : '1023'
    }
  };

  private mockBlackbird: MetaDataProperty = {
    schemas    : {
      '@context': 'bbContext',
      '@type'   : 'bbType'
    },
    seo        : {
      description: '<h1><b>bbSeoDescription</b></h1>',
      keywords   : undefined,
    },
    socialMedia: {
      description: 'bbSocialMediaDescription',
      twitterId  : '2023'
    }
  };

  private mockShopware: MetaDataProperty[] = [
    {
      schemas    : {
        '@context': 'sw1Context',
        '@type'   : 'JobOffer'
      },
      seo        : {
        description: 'swSeoDescription',
        keywords   : 'shopware, eins'
      },
      socialMedia: {
        description: 'swSocialMediaDescription',
        twitterId  : '2023'
      }
    },
    {
      schemas    : {
        '@context': 'sw2Context',
        '@type'   : 'sw2Type'
      },
      seo        : {
        description: 'swSeoDescription',
        keywords   : 'shopware, zwei'
      },
      socialMedia: {
        description: 'swSocialMediaDescription',
        twitterId  : '2023'
      },
    },
    {
      schemas    : [{
        '@context': 'sw3Context',
        '@type'   : 'JobOffer'
      }],
      seo        : {
        description: 'swSeoDescription',
        keywords   : 'shopware, drei'
      },
      socialMedia: {
        description: 'swSocialMediaDescription',
        twitterId  : '2033'
      },
    }
  ];

  constructor (@Inject(ENVIRONMENT) private environment: ClientEnvironment) {
    // Load main Schema
    // console.log( this.combineMetas ( { mainMeta: this.mockMain, blackbirdMeta: this.mockBlackbird, shopwareMeta: this.mockShopware } ) );
  }

  /*
   * Die combineMetas-Funktion erstellt aus den Standard-Metadaten, den Daten aus Blackbird und den Daten aus Shopware ein neues Metadaten-Objekt.
   * Sollten für Blackbird oder Shopware ein Array aus mehreren Metadaten übergeben werden, müssen die Daten, die die höchste Priorität haben,
   * am Ende des Arrays stehen
   *
   * @param input: Objekt, dass die Metadaten-Quellen enthält
   * @param mainMeta: Standard-Metadaten
   * @param blackbirdMeta: Ein einzelnes Objekt oder ein Array an Metadaten aus Blackbird.
   * @param shopwareMeta: Ein einzelnes Objekt oder ein Array an Metadaten aus Shopware.
   */
  combineMetas ( input: {
    mainMeta: MetaDataProperty,
    blackbirdMeta?: MetaDataProperty | MetaDataProperty[],
    shopwareMeta?: MetaDataProperty | MetaDataProperty[],
    joinMeta?: MetaDataProperty | MetaDataProperty[],
    includeBlackbirdSchemasOnly?: boolean,
    includeShopwareSchemasOnly?: boolean,
  } ): MetaData {
    const joinMetas: MetaData[]  =
      (input.joinMeta
        ? [].concat ( input.joinMeta )
        : [])
        .map ( (value: MetaData) => removeEmpty ( value, this.environment ) )
        .map( (value: MetaData) => ({...value, schemas: value.schemas ? ([].concat(value.schemas)) : []}) as MetaData );

    /* Wir stellen sicher, dass wir für Blackbird und Shopware immer ein Array erhalten, egal, was übergeben wurde.
     * Anschließend werden leere Inhalte entfernt und die Schema-Objekte in ein eigenes Array ausgelagert
     */
    const shopwareMetas: MetaData[]  =
            (input.shopwareMeta
              ? [].concat ( input.shopwareMeta )
              : [])
              .map ( (value: MetaData) => removeEmpty ( value, this.environment ) )
              .map( (value: MetaData) => ({...value, schemas: value.schemas ? ([].concat(value.schemas)) : []}) as MetaData );


    const blackbirdMetas: MetaData[] =
            (input.blackbirdMeta
              ? [].concat ( input.blackbirdMeta )
              : [])
              .map ( value => removeEmpty ( value, this.environment ) )
              .map( (value: MetaData) => ({...value, schemas: value.schemas ? ([].concat(value.schemas)) : []}) as MetaData );


    // Die Standard-Metadaten werden übernommen, leere Inhalte entfernt
    const mainMeta: MetaData = removeEmpty( input.mainMeta, this.environment );

    // console.log ( 'cfg schemas', mainMeta.schemas ? ([].concat(mainMeta.schemas)) : [] )
    // console.log ( 'bb schemas', ( blackbirdMetas.map( value => value.schemas ).reduce( (acc, currentValue) => acc.concat( currentValue), [] ) ) )
    // console.log ( 'sw schemas', ( shopwareMetas.map( value => value.schemas ).reduce( (acc, currentValue) => acc.concat( currentValue), [] ) ) )

/*    console.log ( blackbirdMetas.map( value => value.schemas )
                                .reduce( (acc, currentValue) => {
                                  debugger
                                  return acc.concat ( currentValue );
                                }, [] ) );*/
    /*
     * Die Meta-Daten werden mit den Daten aus Blackbird und Shopware überschrieben.
     * Die Schemas aus Blackbird und Shopware werden in einzelne Arrays aufgeteilt, damit sie korrekt hinzugefügt werden.
     *
     * Bei SEO und socialMedia:
     * Die Blackbird-Daten überschreiben sich ggf. auch gegenseitig, sollten mehrere Daten vorliegen.
     * Die Daten aus Shopware werden nur hinzugefügt, wenn ein einziges Shopware-Objekt vorliegt.
     */
    const meta: MetaData = {
      schemas     : [
          ... ( mainMeta.schemas ? ([].concat(mainMeta.schemas)) : [] ),
          ... ( blackbirdMetas.map( value => value.schemas ).reduce( (acc, currentValue) => acc.concat( currentValue), [] ) ),
          ... ( shopwareMetas.map( value => value.schemas ).reduce( (acc, currentValue) => acc.concat( currentValue), [] ) ),
          ... ( joinMetas.map( value => value.schemas ).reduce( (acc, currentValue) => acc.concat( currentValue), [] ) ),
      ],
      seo        : {
        ...mainMeta.seo,
        ...(input.includeBlackbirdSchemasOnly
          ? {}
          : blackbirdMetas.reduce( (acc: Seo, meta) => ({ ...acc, ... meta.seo }), {} )),
        ...((shopwareMetas.length === 1 && !input.includeShopwareSchemasOnly) ? shopwareMetas[0].seo : {} ),
        ...((joinMetas.length === 1) ? joinMetas[0].seo : {} )
      },
      socialMedia: {
        ...mainMeta.socialMedia,
        ...(input.includeBlackbirdSchemasOnly
          ? {}
          : blackbirdMetas.reduce( (acc: SocialMedia, meta) => ({ ...acc, ... meta.socialMedia }), {} )),
        ...((shopwareMetas.length === 1 && !input.includeShopwareSchemasOnly) ? shopwareMetas[0].socialMedia : {} ),
        ...((joinMetas.length === 1) ? joinMetas[0].socialMedia : {} )
      }
    };


    /**
     * remove schemas if they have not an Type
     */
    meta.schemas = meta.schemas.filter( schema => schema['@type'] !== undefined );
    /**
     * Entferne die Duplikate aus den Schema-Daten
     */
    meta.schemas = [...new Set (meta.schemas.map ( schema => JSON.stringify( schema) )) ].map( schemaStr => JSON.parse( schemaStr ));

    /*
     * Es wird überprüft, ob mehrere Schemas vom selben Typ vorhanden sind, die in der Blacklist stehen.
     *
     * Sollte das der Fall sein, werden all diese Einträge entfernt.
     */
    this.metaDoubleBlacklist.forEach( value => {
      if ( meta.schemas.filter( schema => schema['@type'] === value ).length > 1 ) {
        meta.schemas = meta.schemas.filter( schema => schema['@type'] !== value )
      }
    });

    /* Entferne die doppelten Schemas, bis auf das erste jeweils gefundene. Dieses wird beibehalten. */
    this.metaOnlySingleBlacklist.forEach( value => {
      if ( meta.schemas.filter( schema => schema['@type'] === value ).length > 1 ) {
        let oneOnly;
        meta.schemas = meta.schemas.filter( schema => {
          const notMatched = schema['@type'] !== value;
          if ( !notMatched && !oneOnly ) oneOnly = schema;
          return notMatched;
        } )
        if ( oneOnly ) meta.schemas.push( oneOnly )
      }
    });
    // meta.schemas = meta.schemas.map( value => removeEmpty( value ))
    return meta;
  };
}
