import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { JobsEffectsService } from './jobs.effects.service';
import { jobsReducer } from './jobs.reducer';
import { jobsFeatureKey } from './jobs.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature ( jobsFeatureKey, jobsReducer ),
    EffectsModule.forFeature ( [JobsEffectsService] ),
  ]
})
export class JobsStoreModule { }
