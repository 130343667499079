import { Action, createReducer, on } from '@ngrx/store';
import { REFERENCES_CATEGORIES_SET, REFERENCES_LAZY_LOADED_SET, REFERENCES_SET, REFERENCES_TOTAL_SET } from './project-references.actions';
import { referencesAdapter, ReferencesState } from './project-references.state';

const _referencesReducer = createReducer (
  referencesAdapter.getInitialState(),
  on ( REFERENCES_SET, ( state, { references } ) => referencesAdapter.setAll( references, state ) ),
  on ( REFERENCES_LAZY_LOADED_SET, ( state, { references } ) => referencesAdapter.addMany( references, state ) ),
  on ( REFERENCES_CATEGORIES_SET, ( state, { categories } ) =>  ( { ...state, categories } ) ),
  on ( REFERENCES_TOTAL_SET, ( state, { total } ) =>  ( { ...state, total } ))
);

export const projectReferencesReducer = ( state: ReferencesState, action: Action ) => _referencesReducer ( state, action );
