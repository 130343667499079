import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ErrorState } from './errorInitState';

export const errorFeatureKey = 'error';

export const selectErrorState = createFeatureSelector<ErrorState> ( errorFeatureKey );

export const selectErrorActiveState  = createSelector (
  selectErrorState,
  ( state: ErrorState ) => state.active
);
export const selectErrorIsFatalState = createSelector (
  selectErrorState,
  ( state: ErrorState ) => state.isFatal
);

