import {Component, Input} from '@angular/core';

import {ShorthandIcon} from './shorthand-icons.interface';

@Component({
  selector: 'dwg-shorthand-icons',
  templateUrl: './shorthand-icons.component.html',
  styleUrls: ['./shorthand-icons.component.scss']
})
export class ShorthandIconsComponent {
  @Input() shorthandIcons: ShorthandIcon[];
}
