import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicComponentsRenderingComponent } from './dynamic-components-rendering.component';
import { DynamicComponentsRenderingDirective } from './dynamic-components-rendering.directive';



@NgModule({
  declarations: [DynamicComponentsRenderingComponent, DynamicComponentsRenderingDirective],
  imports: [
    CommonModule
  ],
  exports: [DynamicComponentsRenderingComponent]
})
export class DynamicComponentsRenderingModule { }
