import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ERROR_FEEDBACK_MAIL, SHOW_ERROR_HANDLER } from '@nettrek-angular-libs/common-store';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AppErrorHandler } from './app-error-handler';
import { ErrorDialogComponent } from './error-mat-dialog/error-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { INTERNAL_LIB_ERROR_FEEDBACK_MAIL } from '../common-injection-token';

@NgModule ( {
  declarations: [ErrorDialogComponent],
  imports     : [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  providers   : [
    { provide: INTERNAL_LIB_ERROR_FEEDBACK_MAIL, useExisting: ERROR_FEEDBACK_MAIL },
    {
      provide: SHOW_ERROR_HANDLER, useClass: AppErrorHandler, deps: [
        Store,
        MatDialog,
      ]
    }
  ]
} )
export class MatDialogErrorHandlerModule {
}
