import { createAction, props } from '@ngrx/store';
import { BlackbirdConfig } from './blackbird-config.interface';

// this store is used to store the configuration data (data shared this project and default SEO data) from Blackbird

export const CONFIG_BLACKBIRD_LOAD  = createAction ( '[CONFIG] BLACKBIRD LOAD' );

export const CONFIG_BLACKBIRD_SET  = createAction ( '[CONFIG] BLACKBIRD SET',
  props<{ configData: BlackbirdConfig }> ()
);
