<ng-container *ngIf='config$ | async as config'>

  <div class="footer-information main-grid">
    <div class="footer-information-content content-container">
      <div class="about-dwg">
        <h3>
          <a class="logo-link" [routerLink]="'/'">
            <ed-clients-lazy-bb-picture class="logo contain" height="80"
                                     [blackbirdImage]="config.logo"></ed-clients-lazy-bb-picture>
          </a>
        </h3>
        <p [innerHTML]="config?.shortDescription"></p>
      </div>
      <div class="visit-us" *ngIf="config.addressText">
        <h3>Besuchen Sie uns</h3>
        <p class="dwg-address" [innerHTML]="config.addressText">
        </p>
      </div>
      <div class="contact footer-contact-links">
        <h3>Kontakt</h3>
        <div><a [href]="config.callNumText | germanTelOrFaxConverter"
                *ngIf="config.callNumText">Tel: {{config.callNumText}}</a></div>
        <div><a [href]="config.faxNumText | germanTelOrFaxConverter"
                *ngIf="config.faxNumText">Fax: {{config.faxNumText}}</a></div>
        <div><a [href]="'mailto:' + config.emailText" *ngIf="config.emailText">E-Mail: {{config.emailText}}</a></div>
      </div>
      <div class="footer-logos">
        <ed-clients-lazy-bb-picture *ngIf="config.memberImage" class="footer-logo contain" height="80"
                                 [blackbirdImage]="config.memberImage"></ed-clients-lazy-bb-picture>
        <ed-clients-lazy-bb-picture *ngIf="config.weEducateImage" class="footer-logo contain" height="80"
                                 [blackbirdImage]="config.weEducateImage"></ed-clients-lazy-bb-picture>
        <ed-clients-lazy-bb-picture *ngIf="config.vdivImage" class="footer-logo contain" height="80"
                                    [blackbirdImage]="config.vdivImage"></ed-clients-lazy-bb-picture>
      </div>
    </div>
  </div>

  <div class="footer-bar main-grid">
    <div class="footer-bar-content content-container">
      <div class="copyright">
        {{ config.mark }}
      </div>

      <nav class="legal-links">
        <ng-container *ngFor="let statement of footerLinks">
          <a class="legal-link" [routerLink]="statement.link">{{ statement.name }}</a>
        </ng-container>
      </nav>
    </div>
  </div>
</ng-container>
