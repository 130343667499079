import version from '../../../../package.json';

export const environment = {
    production      : true,
    version         : (version || 'unknown'),
    ssr             : {
        jsonHost: 'https://blackbird.echtdorsten.de/',
        shopHost: 'https://shop.echtdorsten.de/',
    },
    browser         : {
        host    : 'https://dwg-dorsten.de',
        jsonHost: 'https://blackbird.echtdorsten.de/',
        shopHost: 'https://shop.echtdorsten.de/',
    },
    seo             : {
        suffix: ' | dwg seo',
    },
    blackbird       : {
        projectSlug: 'dwg',
        content    : 'ed-clients/staticpages',
        posts      : 'ed-clients/posts',
        config     : 'ed-clients/staticpages/dwg-premium-config?images=object',
        token      : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIzMzgiLCJleHAiOjE5NDM5NTc2MTd9.jV5Uo4ocNILdZJfMOHaHrXtiNjiqN4DMqrVUQZULT-w',
    },
    email           : {
        apply_online     : 'https://blackbird.echtdorsten.de/ed-clients/dwg/online-bewerben',
        contact_for_offer: 'https://blackbird.echtdorsten.de/ed-clients/dwg/anfrage-senden',
        contact          : 'https://blackbird.echtdorsten.de/ed-clients/dwg/kontakt',
        emergencyContact : 'https://blackbird.echtdorsten.de/ed-clients/dwg/reperatur-formular',
    },
    shopware        : {
        products            : 'store-api/product',
        realEstatesAccessKey: 'SWSCMEPPTE1ZD3VBZUTMVLPTMQ',
        jobsAccessKey       : 'SWSCRHBAOG9OQ0RDCMV1A3HZUQ',
        vendorId            : '029661015f384a6d8f12b1b045db9640',
    },
    documentsApi: 'https://documents-backend.echtdorsten.de',
    useReduxDevTools: false,
    noindex         : false,
};
