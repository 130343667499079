import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

import {IntersectionObserverService} from './intersection-observer.service';

@Component({ template: '' })
export abstract class AbstractLazyPictureComponent implements OnInit, OnDestroy {

  @Input() width: number;
  @Input() height: number;

  /**
   * Disables lazy loading completely
   * @param value
   */
  protected _notLazy = false;
  @Input() set notLazy(value: any) {
    this._notLazy = coerceBooleanProperty(value);
  }

  /**
   * Disables lazy loading for the first render
   * @param value
   */
  protected _firstNotLazy = false;
  @Input() set firstNotLazy(value: any) {
    this._firstNotLazy = coerceBooleanProperty(value);
  }

  @Output() loadEvent: EventEmitter<Event> = new EventEmitter<Event>();

  imgSrc: string;
  protected initialized = false;

  constructor(
    protected intersectionObserver: IntersectionObserverService,
    protected el: ElementRef,
  ) {
  }

  ngOnInit() {
    this.init();
    this.initialized = true;
  }

  ngOnDestroy() {
    this.intersectionObserver.remove(this.el.nativeElement);
    this.intersectionObserver.initalRenderFinished();
  }

  abstract showImg()

  protected init() {
    if(!this._notLazy) {
      this.intersectionObserver.add(this.el.nativeElement, this.showImg.bind(this), this._firstNotLazy);
    } else {
      this.showImg();
    }
  }

  protected checkReInit() {
    if(this.initialized) {
      this.intersectionObserver.remove(this.el.nativeElement);
      this.init();
    }
  }
}
