import { ErrorCodeDetails } from '../error-codes';

// muss leer sein um zu schauen ob das laden erfolgreich war

export interface ErrorState {
  active: ErrorCodeDetails | undefined,
  isFatal: boolean | undefined
}

export const errorInitState: ErrorState = { active: undefined, isFatal: undefined };


