<div class="site">
  <dwg-svg-sprites></dwg-svg-sprites>
  <dwg-header class="main-grid"></dwg-header>
  <dwg-shorthand-icons [shorthandIcons]="shorthandIcons"></dwg-shorthand-icons>

  <main>
    <ng-container *ngIf="metaInformation.schemas | async as schemas">
      <ngx-json-ld *ngFor='let schema of schemas' [json]="schema"></ngx-json-ld>
    </ng-container>
    <router-outlet></router-outlet>
  </main>

  <dwg-footer></dwg-footer>
</div>
