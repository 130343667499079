import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'neuigkeiten-info',
    loadChildren: () => import('./news-info/news-info.module').then(m => m.NewsInfoModule)
  },
  {path: 'kundenservice/ansprechpartner', loadChildren: () => import('./team/team.module').then(m => m.TeamModule)},
  {
    path: 'angebote',
    loadChildren: () => import('./our-offers/our-offers.module').then(m => m.OurOffersModule)
  },
  {
    path: 'unternehmen/ueber-uns',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {path: 'unternehmen/partner', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule)},
  {
    path: 'verwaltung/wir-kaufen',
    loadChildren: () => import('./we-purchase/we-purchase.module').then(m => m.WePurchaseModule)
  },
  {
    path: 'kundenservice/notdienst',
    loadChildren: () => import('./emergency-contact/emergency-contact.module').then(m => m.EmergencyContactModule)
  },
  {path: 'karriere', loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule)},
  {
    path: 'immobilien/:id',
    loadChildren: () => import('./immobilien-details/immobilien-details.module').then(m => m.ImmobilienDetailsModule)
  },
  {
    path: 'online-bewerben',
    loadChildren: () => import('./application-form/application-form.module')
      .then(m => m.ApplicationFormModule)
  },
  {path: 'impressum', loadChildren: () => import('./impressum/impressum.module').then(m => m.ImpressumModule)},
  {path: 'kontakt', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)},
  {
    path: 'kundenservice/mieter-tipps',
    loadChildren: () => import('./tenant-tips/tenant-tips.module').then(m => m.TenantTipsModule)
  },
  {
    path: 'verwaltung/weg-verwaltung',
    loadChildren: () => import('./weg-verwaltung/weg-verwaltung.module').then(m => m.WegVerwaltungModule)
  },
  {
    path: 'verwaltung/hausverwaltung',
    loadChildren: () => import('./house-management/house-management.module').then(m => m.HouseManagementModule)
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./datenschutz/datenschutz.module').then(m => m.DatenschutzModule)
  },
  {
    path: 'seite-nicht-gefunden',
    loadChildren: () => import('./pagenotfound/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  { path: 'kundenservice/dokumente', loadChildren: () => import('./tenant-documents/tenant-documents.module').then(m => m.TenantDocumentsModule) },
  { path: 'kundenservice', loadChildren: () => import('./customer-service/customer-service.module').then(m => m.CustomerServiceModule) },
  {path: '**', redirectTo: 'seite-nicht-gefunden'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: false,
        initialNavigation: 'enabled',
        // anchorScrolling: 'enabled',
        scrollPositionRestoration: 'disabled', // see app ts
        // scrollOffset: [0,
        //   100
        // ]
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
