import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export function slideInFromRight (
  period = '250ms',
  voidTranslateX = '80%',
  hiddenLeftTranslateX = '-80%',
  visibleTranslateX = '0' ): AnimationTriggerMetadata {

  return trigger('slideInFromRight', [
    state('void', style({ transform: `translateX(${voidTranslateX})` })),
    state('visible', style({ transform: `translateX(${visibleTranslateX})` })),
    state('hiddenLeft', style({ transform: `translateX(${hiddenLeftTranslateX})` })),
    transition( '* => *', animate(period) )
  ])

}

export function slideInFromLeft (
  period = '250ms',
  voidTranslateX = '-80%',
  hiddenLeftTranslateX = '80%',
  visibleTranslateX = '0' ): AnimationTriggerMetadata {

  return trigger('slideInFromLeft', [
    state('void', style({ transform: `translateX(${voidTranslateX})` })),
    state('visible', style({ transform: `translateX(${visibleTranslateX})` })),
    state('hiddenRight', style({ transform: `translateX(${hiddenLeftTranslateX})` })),
    transition( '* => *', animate(period) )
  ])

}
