import {Component, ElementRef, Input} from '@angular/core';

import {BlackbirdImage} from '../../blackbird-resolver-services/blackbird.interface';
import {AbstractLazyPictureComponent} from '../abstract-lazy-picture.component';
import {IntersectionObserverService} from '../intersection-observer.service';

@Component({
  selector: 'ed-clients-lazy-bb-picture',
  templateUrl: './lazy-bb-picture.component.html',
  styleUrls: ['./lazy-bb-picture.component.scss']
})
export class LazyBbPictureComponent extends AbstractLazyPictureComponent {

  bbImage: BlackbirdImage;
  @Input() set blackbirdImage(value: BlackbirdImage) {
    this.bbImage = value;
    super.checkReInit();
  }

  webpSrc: string;
  imgSrc: string;

  constructor(
    protected intersectionObserver: IntersectionObserverService,
    protected el: ElementRef,
  ) {
    super(intersectionObserver, el);
  }

  showImg() {
    this.webpSrc = this.bbImage?.webp;
    this.imgSrc = this.bbImage?.uri;
  }
}
