import { EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { JOBS_SET } from './jobs.actions';
import { Job } from './jobs.interface';
import { jobsAdapter } from './jobs.state';

const _jobsReducer = createReducer (
  jobsAdapter.getInitialState(),
  on ( JOBS_SET, ( state, { jobs } ) => {
    return jobsAdapter.setAll ( jobs, state );
  } )
);

export const jobsReducer = ( state: EntityState<Job>, action: Action ) => _jobsReducer ( state, action );
