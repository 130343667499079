import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {
  NewsService,
  selectNews,
} from '@ed-clients/common-store';

import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { BlackbirdPost } from './blackbird.interface';

// prerequisites:
// params key :slug is used
// use /seite-nicht-gefunden

@Injectable({
  providedIn: 'root'
})
export class PostResolver implements Resolve<BlackbirdPost> {

  constructor(
    private store: Store,
    private router: Router,
    // private metaInfoService: MetaInfoService,
    private newsService: NewsService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BlackbirdPost> {
    return this.store.select( selectNews ).pipe(
      map( news => {
        return news.filter(post => post.slug === route.params.slug)[0]
      } ),
      switchMap( post => {
        if( !post ) {
          return this.newsService.getSinglePost( { slug: route.params.slug } ).pipe(
            map( postRes => postRes.data ),
            take(1),
            catchError( () => {
              this.router.navigateByUrl('/seite-nicht-gefunden');
              return EMPTY;
            } )
          )
        } else {
          return of(post)
        }
      } ),
      take(1)
    )
  }
}
