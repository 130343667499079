import { createSelector } from '@ngrx/store';
import { RootStoreState } from '../root-state';
import { CommunicationState } from './communication.state';

export const selectComState = (state: RootStoreState) => state.com;

export const selectCommunicationProgress = createSelector (
  selectComState,
  ( state: CommunicationState ) => state.progress
);
