import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LazyPictureModule} from '@ed-clients/shared';

import {ShorthandIconsComponent} from './shorthand-icons.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [ShorthandIconsComponent],
    imports: [
        CommonModule,
        LazyPictureModule,
        RouterModule,
        MatSidenavModule,
        MatIconModule
    ],
  exports: [ShorthandIconsComponent]
})
export class ShorthandIconsModule {
}
