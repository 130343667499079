import { Action, createReducer, on } from '@ngrx/store';
import { CONFIG_BLACKBIRD_SET } from './blackbird-config.actions';
import { BlackbirdConfig } from './blackbird-config.interface';

const _blackbirdConfigReducer       = createReducer (
  undefined,
  on ( CONFIG_BLACKBIRD_SET, ( state, { configData } ) => ({ ...state, ...configData }) )
);

export const blackbirdConfigReducer = ( state: BlackbirdConfig, action: Action ) => _blackbirdConfigReducer ( state, action );
