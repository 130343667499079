import { createAction, props } from '@ngrx/store';
import { Reference, ReferencesCategory } from './project-references.interface';

// page && limit
// This action supports lazyloading(partial load references) and non-lazyloading(load all references)
export const REFERENCES_LOAD = createAction ( '[REFERENCES] LOAD REFERENCES',
  props<{ categoryId?: string, page?: number, limit?: number }> ()
);

export const REFERENCES_SET = createAction ( '[REFERENCES] SET REFERENCES',
  props<{ references: Reference[] }> ()
);

// for lazy-loading references (instead of setAll, addMany is used in reducer)
export const REFERENCES_LAZY_LOADED_SET = createAction ( '[REFERENCES] LAZY LOADED SET REFERENCES',
  props<{ references: Reference[] }> ()
);

export const REFERENCES_TOTAL_SET = createAction ( '[REFERENCES] TOTAL SET REFERENCES',
  props<{ total: number }> ()
);

export const REFERENCES_CATEGORIES_LOAD = createAction ( '[REFERENCES] LOAD CATEGORIES REFERENCES' );

export const REFERENCES_CATEGORIES_SET = createAction ( '[REFERENCES] SET CATEGORIES REFERENCES',
  props<{ categories: ReferencesCategory[] }> ()
);
