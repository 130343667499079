import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';

import { NEWS_LAZY_LOADED_SET, NEWS_LOAD, NEWS_SET, NEWS_SET_Pagination } from './news.actions';
import { NewsService } from './news.service';

@Injectable()
export class NewsEffectsService {

  constructor(
    private actions: Actions,
    private newsService: NewsService
  ) {
  }

  loadNews = createEffect( () => this.actions.pipe(
    ofType( NEWS_LOAD ),
    mergeMap( action => this.newsService.getNews( action ).pipe(
      mergeMap( ( res ) => {
        const news = action.page? res.data.map( element => {
          element.page = action.page;
          return element;
        } ) : res.data;

        if( action.page === 1 || (!action.page && !action.limit) ) { // if first page or page & limit undefined
          return [
            NEWS_SET( { news } ),
            NEWS_SET_Pagination( { pagination: res.pagination } )
          ];
        } else return [ // non first page
          NEWS_LAZY_LOADED_SET( { news } )
        ]
      } )
    ) )
  ), { dispatch: true } );
}
