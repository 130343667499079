import { createFeatureSelector, createSelector } from '@ngrx/store';
import { videosAdapter, VideosState } from './videos.state';

export const videosFeatureKey  = 'videos';
export const selectVideosState = createFeatureSelector<VideosState> ( videosFeatureKey );

export const selectVideos = createSelector (
    selectVideosState,
    videosAdapter.getSelectors ().selectAll,
);

export const selectVideosPagination = createSelector (
    selectVideosState,
    ( state: VideosState ) => state.pagination,
);

export const selectVideosByPage = createSelector (
    selectVideos,
    ( videos, page ) => {
        return videos.filter ( post => post.page === page );
    },
);

// export const selectVideosBySlug = createSelector (
//     selectVideos,
//     ( videos, slug ) => {
//         return videos.filter ( post => post.slug === slug );
//     },
// );
