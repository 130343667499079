import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {
  Job,
  JOBS_LOAD,
  selectJobs,
} from '@ed-clients/common-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { MetaInfoService } from '../blackbird-resolver-services/meta-info.service';

// prerequisites:
// params key :jobSlug is used
// use /seite-nicht-gefunden

@Injectable({
  providedIn: 'root'
})
export class JobDetailsResolver implements Resolve<Job> {
  loaded = false; // this boolean prevents the store from dispatching again and again when there are no jobs in shopware

  constructor(private store: Store, private router: Router, private metaInfoService: MetaInfoService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Job> {

    return this.store.select( selectJobs ).pipe(
      tap( jobs => {
        if( jobs.length === 0 && !this.loaded ) {
          this.store.dispatch( JOBS_LOAD() );
        }
      } ),
      map( jobs => {
        return jobs.filter(job => job.customFields.ed_cf_slug === route.params.jobSlug)[0]
      } ),
      filter( job => {
        if( !job && this.loaded ) {
          this.router.navigateByUrl('/seite-nicht-gefunden');
        }
        this.loaded = true;
        return !!job;
      } ),
      take(1)
    )
  }
}
