import { createFeatureSelector, createSelector } from '@ngrx/store';
import { faqAdapter, FaqState } from './faq.state';

export const faqFeatureKey  = 'faq';
export const selectFaqState = createFeatureSelector<FaqState> ( faqFeatureKey );

export const selectFaq = createSelector (
    selectFaqState,
    faqAdapter.getSelectors ().selectAll,
);

export const selectFaqPagination = createSelector (
    selectFaqState,
    ( state: FaqState ) => state.pagination,
);

export const selectFaqByPage = createSelector (
    selectFaq,
    ( faq, page ) => {
        return faq.filter ( post => post.page === page );
    },
);

export const selectFaqBySlug = createSelector (
    selectFaq,
    ( faq, slug ) => {
        return faq.filter ( post => post.slug === slug );
    },
);
