// this pipe is used to convert a given string into tel or fax string, so that it can be used then
// in 'a' tag href
// It supports only german prefix!

import { Pipe, PipeTransform } from '@angular/core';
// Pipe is pure function. The output data is cached, therefore, if the same value is passed, pipe wouldnt rerun

@Pipe( { name: 'germanTelOrFaxConverter' } )
export class GermanTelOrFaxPipe implements PipeTransform { // eslint-disable-line
  transform( telOrFaxText: string, isFax = false ): string {
    const telOrFaxValue = telOrFaxText.replace( /\s|\D/g, '' ).replace( /^\d*49/g, '' ).replace( /^0?/g, '' );
    // replace first all spaces and non-digits with empty string,
    // remove then all digits in front of 49 and 49 itself if any
    // subsequently remove 0 in the beginning if any
    // https://regex101.com/

    if( !isFax ) {
      return `tel:+49${ telOrFaxValue }`;
    } else {
      return `fax:+49${ telOrFaxValue }`;
    }
  }
}
