import {Component} from '@angular/core';

@Component({
  selector: 'dwg-svg-sprites',
  templateUrl: './svg-sprites.component.html',
  styleUrls: ['./svg-sprites.component.scss'],
})
export class SvgSpritesComponent {

}
