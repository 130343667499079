import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MetaInformationService } from '@ed-clients/shared';
import { environment } from '../environments/environment';
import {selectConfigState} from "@nettrek-angular-libs/common-store";
import {filter, map, take} from "rxjs/operators";
import {Config} from "./app-init.interface";
import {Store} from "@ngrx/store";
import {ShorthandIcon} from "./shared-across-dwg-only/shorthand-icons/shorthand-icons.interface";
import {Router, Scroll} from '@angular/router';
import {ViewportScroller} from '@angular/common';

@Component ( {
    selector   : 'dwg-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
} )
export class AppComponent {
    title = 'dwg';
    shorthandIcons: ShorthandIcon[];

    constructor(
        public readonly metaInformation: MetaInformationService,
        private readonly meta: Meta,
        private store: Store, router: Router, viewportScroller: ViewportScroller
    ) {

        if ( environment.noindex ) {
            this.meta.updateTag ( { name: 'robots', content: 'NOINDEX, NOFOLLOW' } );
        }

      this.store.select(selectConfigState).pipe(
        map(config => config.data as Config),
        filter(config => !!config),
        take(1)
      ).subscribe(configData => {
        this.shorthandIcons = configData.shorthandIcons;
      });

      // since enabled scroll restoration doesnt work fine on ios system(on 13 onwards), custom logic is used
      // settimeout is used to implement the logic at last
      router.events.pipe(
        filter((e): e is Scroll => e instanceof Scroll)
      ).subscribe(e => {
        if (e.position) {
          // backward navigation
          setTimeout(() => {viewportScroller.scrollToPosition(e.position); }, 0);
        } else if (e.anchor) {
          // anchor navigation
          setTimeout(() => {viewportScroller.scrollToAnchor(e.anchor); }, 0);
        } else {
          // forward navigation
          setTimeout(() => {viewportScroller.scrollToPosition([0, 0]); }, 0);
        }
      });
    }
}
