import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { JoinJob } from './jobs-join.interface';
import { jobsAdapter } from './jobs-join.state';

export const joinJobsFeatureKey  = 'joinJobs';
export const selectJoinJobsState = createFeatureSelector<EntityState<JoinJob>> ( joinJobsFeatureKey );

export const selectJoinJobs = createSelector (
  selectJoinJobsState,
  jobsAdapter.getSelectors().selectAll
);
