import { Action, createReducer, on } from '@ngrx/store';
import { VIDEOS_LAZY_LOADED_SET, VIDEOS_SET, VIDEOS_SET_Pagination } from './videos.actions';
import { initialVideosState, videosAdapter, VideosState } from './videos.state';

const _videosReducer = createReducer (
  initialVideosState,
  on ( VIDEOS_SET, ( state, { videos } ) => videosAdapter.setAll ( videos, state ) ),
  on ( VIDEOS_LAZY_LOADED_SET, ( state, { videos } ) => videosAdapter.addMany ( videos, state ) ),
  on( VIDEOS_SET_Pagination, ( state, { pagination } ) =>
    ( { ...state, pagination } )
  )
);

export const videosReducer = ( state: VideosState, action: Action ) => _videosReducer ( state, action );
