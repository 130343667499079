import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LazyPictureComponent} from './lazy-picture/lazy-picture.component';
import {LazyBbPictureComponent} from './lazy-bb-picture/lazy-bb-picture.component';
import {LazySwPictureComponent} from './lazy-sw-picture/lazy-sw-picture.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LazyPictureComponent,
    LazyBbPictureComponent,
    LazySwPictureComponent
  ],
  exports: [
    LazyPictureComponent,
    LazyBbPictureComponent,
    LazySwPictureComponent
  ],
})
export class LazyPictureModule {
}
