import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

console.log('dwg: ' + environment.version);

if (environment.production) {
  enableProdMode();

  // disable console in production
  const console = (window.console || {}) as Console;
  for (const consoleKey in console) {
    if (!!consoleKey && consoleKey !== 'error') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      console[consoleKey] = () => {
      };
    }
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
