import { createFeatureSelector, createSelector } from '@ngrx/store';
import { referencesAdapter, ReferencesState } from './project-references.state';

export const referencesFeatureKey  = 'references';
export const selectReferencesState = createFeatureSelector<ReferencesState> ( referencesFeatureKey );

export const selectProjectReferences = createSelector (
  selectReferencesState,
  referencesAdapter.getSelectors ().selectAll,
);

export const selectReferencesCategories = createSelector (
  selectReferencesState,
  ( state: ReferencesState ) => {
    if ( state.categories ) {
      return state.categories;
    } else {
      return undefined;
    }
  },
);

export const selectReferencesTotal = createSelector (
  selectReferencesState,
  ( state: ReferencesState ) => {
    if ( state.total ) {
      return state.total;
    } else {
      return undefined;
    }
  },
);

export const selectReferencesByPage = createSelector (
  selectProjectReferences,
  ( references, page ) => {
    return references.filter ( reference => reference?.page?.toString () === page?.toString () );
  },
);

