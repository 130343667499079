import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientEnvironment, ENVIRONMENT, SwStoreResponse } from '@ed-clients/shared';
import { map } from 'rxjs/operators';

import { Job } from './jobs.interface';

@Injectable( {
  providedIn: 'root'
} )
export class JobsService {

  constructor(
    private http: HttpClient,
    @Inject( ENVIRONMENT ) private environment: ClientEnvironment,
    @Inject( PLATFORM_ID ) private platformId
  ) {
  }

  getJobsUrl(): string {
    let host = this.environment.browser.shopHost;
    if ( isPlatformServer( this.platformId ) ) {
      host = this.environment.ssr.shopHost;
    }
    return host + this.environment.shopware.products;
  }

  getJobs() {
    const body = {
      includes: {
        product: [ 'id', 'name', 'customFields', 'description', 'cover' ],
        product_media: [ 'media' ], // cover, media's url
        media: [ 'url' ], // cover, media's url,
      },
      sort: [
        { field: 'createdAt', order: 'ASC' }
      ],
      filter: [
        { type: 'equals', field: 'vendor.id', value: this.environment.shopware.vendorId }
      ]
    };
    const options = { headers: { 'sw-access-key': this.environment.shopware.jobsAccessKey } };

    return this.http.post<SwStoreResponse<Job>>( this.getJobsUrl(), body, options ).pipe(
      map(swRes =>  swRes.elements)
    );
  }
}
