<form #myForm="ngForm" class="form" (ngSubmit)="sendData()" *ngIf="dynamicFormData && formGroup" [formGroup]="formGroup">
  <ng-container *ngFor="let item of dynamicFormData.items">
    <ng-container [ngSwitch]="item.type">
      <ng-container
        *ngSwitchCase="'date'">
        <mat-form-field [hideRequiredMarker]="hideRequiredMark" [appearance]="inputFormFieldStyle">
          <mat-label>{{item.label}}</mat-label>
          <input matInput [matDatepicker]="picker" [formControl]="item.formControl"
                 [min]="item.minDate" [max]="item.maxDate" [required]="item.required"
          >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
          <mat-hint>{{item.hint}}</mat-hint>
          <mat-error *ngIf="item.formControl.invalid">{{item.error}}</mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="'checkbox'">
        <div
          *ngIf="item.title"
          class="form-field-title"
          [id]="'form-field-title-' + item.key"
        >{{item.title}}</div>
        <div
          class="form-field form-checkbox"
          [id]="'form-field-' + item.key"
        >
          <mat-checkbox
            [formControl]="item.formControl"
            [required]="item.required"
            color="primary"
          >
            <span class="checkbox-label" [innerHTML]="item.label"></span>
          </mat-checkbox>
          <mat-error class="form-error" *ngIf="item.formControl.touched && item.formControl.invalid">{{item.error}}</mat-error>
        </div>
      </ng-container>

      <div *ngSwitchCase="'radio'"
           class="form-field"
           [id]="'form-field-' + item.key"
           [ngClass]="'form-field--' + item.type"
      >
        <mat-label
          class="form-field-title"
          [id]="'form-field-title-' + item.key"
        >{{item.label}}</mat-label>
        <mat-radio-group
          class="form-radio-group"
          [formControl]="item.formControl"
          [required]="item.required"
        >
          <!-- [id]="'form-field-' + item.key" is removed from mat-radio-button because it causes bug-->
          <!-- bug: second radio is not clickable-->
          <mat-radio-button
            class="form-field form-radio"
            *ngFor="let radioButton of item.radioButtons"
            [value]="radioButton.text ? radioButton.value : radioButton"
          >
            {{ radioButton.text ? radioButton.text : radioButton }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-error class="form-error" *ngIf="item.formControl.touched && item.formControl.invalid">{{item.error}}</mat-error>
      </div>

      <div
        *ngSwitchDefault
        class="form-field"
        [id]="'form-field-' + item.key"
        [ngClass]="'form-field--' + item.type"
      >
        <div
          class="form-field-title"
          [id]="'form-field-title-' + item.key"
          *ngIf="item.title"
        >{{item.title}}</div>
        <mat-form-field [hideRequiredMarker]="hideRequiredMark" [appearance]="inputFormFieldStyle">
          <mat-label>{{item.label}}</mat-label>
          <ng-container [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="'select'">
              <mat-select
                class="form-select"
                disableOptionCentering
                [formControl]="item.formControl"
                [required]="item.required"
              >
                <mat-option class="form-select-option" *ngFor="let option of item.selectOptions" [value]="option">
                  {{option}}
                </mat-option>
              </mat-select>
            </ng-container>
            <textarea
              class="form-textarea"
              *ngSwitchCase="'textarea'"
              [formControl]="item.formControl"
              [placeholder]="item.placeholder"
              [required]="item.required"
              [type]="item.type"
              matInput
              rows="6"
            ></textarea>
            <input
              class="form-input"
              [ngClass]="'form-input--' + item.type"
              *ngSwitchDefault
              [formControl]="item.formControl"
              [placeholder]="item.placeholder"
              [required]="item.required"
              [type]="item.type"
              [readonly]="item.readonly"
              [class.gray-out]="item.readonly"
              [maxLength]="item.maxLength ? item.maxLength : 524288"
              matInput
            >
          </ng-container>
          <mat-icon (click)="togglePwdType(item)" *ngIf="item.isPassword" matSuffix>{{getIcon(item)}}</mat-icon>
          <mat-icon *ngIf="!item.isPassword && item.icon" matSuffix>{{item.icon}}</mat-icon>
          <mat-hint class="form-hint" [id]="null">{{item.hint}}</mat-hint>
          <mat-error class="form-error" *ngIf="item.formControl.invalid">{{item.error}}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </ng-container>

  <ng-content></ng-content>
  <div
    class="buttons-container"
    style="text-align: right;"
    *ngIf="!(displayReset === 'none' && displaySend === 'none')"
  >
    <div class="buttons">
      <button
        class="form-button"
        type="reset"
        color="primary"
        mat-raised-button
        [style.display]="displayReset"
        [disabled]="formGroup.pristine"
      >
        {{dynamicFormData.resetLabel}}
      </button>
      <!-- workaround damit fehlerhafte Felder direkt angezeigt werden -->
      <button
        class="form-button"
        color="primary"
        type="submit"
        mat-raised-button
        [style.display]="displaySend"
        [disabled]="useDisabledButton && formGroup.invalid"
        (click)="triggerValidation(formGroup.invalid)"
      >
        {{dynamicFormData.sendLabel}}
      </button>
    </div>
    <ng-content select="[action]"></ng-content>
  </div>
  <ng-content></ng-content>
</form>
