import { Action, createReducer, on } from '@ngrx/store';
import { FAQ_LAZY_LOADED_SET, FAQ_SET, FAQ_SET_Pagination } from './faq.actions';
import { initialFaqState, faqAdapter, FaqState } from './faq.state';

const _faqReducer = createReducer (
  initialFaqState,
  on ( FAQ_SET, ( state, { faq } ) => faqAdapter.setAll ( faq, state ) ),
  on ( FAQ_LAZY_LOADED_SET, ( state, { faq } ) => faqAdapter.addMany ( faq, state ) ),
  on( FAQ_SET_Pagination, ( state, { pagination } ) =>
    ( { ...state, pagination } )
  )
);

export const faqReducer = ( state: FaqState, action: Action ) => _faqReducer ( state, action );
