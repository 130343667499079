import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { MetaData, Schema } from './meta.interface';
import {ClientEnvironment, ENVIRONMENT} from '../common-injection-token';

@Injectable ( {
  providedIn: 'root',
} )
export class MetaInformationService {

  private schemaSubject = new BehaviorSubject ( null );
  public schemas: BehaviorSubject<Schema[]> = new BehaviorSubject( null );

  constructor( @Inject ( PLATFORM_ID ) private platformId: string,
               @Inject ( DOCUMENT ) private doc: Document,
               @Inject ( ENVIRONMENT ) private environment: ClientEnvironment,
               private title: Title,
               private meta: Meta ) {
  }

  get schema(): Observable<any> {
    return this.schemaSubject.asObservable ();
  }

  /**
   * Updates meta tags, title and JSON-LD data.
   * There is no need to update the tags after the content get pulled from the transfer state so its optional.
   * @param content
   * @param withTags
   */
  updateMetaInfos( meta: MetaData, targetPath: string, withTags = true ) { // TODO withTags sollte entfernt werden!
    let completeTarget;
    if (this.environment.browser.host.slice(-1) === '/') {
      completeTarget = this.environment.browser.host.slice(0, -1);
    } else {
      completeTarget = this.environment.browser.host;
    }

    if (targetPath.slice(0, 1) === '/') {
      targetPath = targetPath.slice(1);
    }

    if(targetPath.length > 0 && targetPath !== '/') {
      completeTarget = completeTarget + '/' + targetPath;
    }

    if (completeTarget.slice(-1) === '/') {
      completeTarget = completeTarget.slice(0, -1);
    }

    this.addCanonicalLink ( meta, completeTarget );

    // console.log('set meta tags');
    this.removeOldMetaTags ();

    this.meta.addTag ( {
      property: 'og:type',
      content: 'website'
    } );

    this.meta.addTag ( {
      property: 'og:url',
      content: completeTarget
    } );

    this.meta.addTag ( {
      property: 'og:locale',
      content: 'de_DE'
    } );
    this.meta.addTag ( {
      property: 'og:locale:alternate',
      content: 'de_AT'
    } );
    this.meta.addTag ( {
      property: 'og:locale:alternate',
      content: 'de_CH'
    } );

    if ( meta.seo ) {
      this.addMetaDescriptionAndTitle ( meta.seo );
    }
    if ( meta.socialMedia ) {
      // Twitter metadata
      this.addDefaultTwitterMetaInfo ( meta.socialMedia );
      this.addTwitterOgTitle ( meta.socialMedia );
      this.addTwitterOgDescription ( meta.socialMedia );
      this.addSeoTwitterOgImg ( meta.socialMedia );
      this.addSeoOgTwitterKeywords ( meta.socialMedia );
    }
    this.schemas.next ( [].concat ( meta.schemas ) );
    return meta.schemas;
    // TODO
    /*
        if (content.schemas?.length > 0) {
          this.schemaSubject.next(content.schemas[0]);
        } else {
          this.schemaSubject.next(null);
        }
        if (content.schemas) {
          this.schemas = content.schemas.slice(1);
          console.log(this.schemas);
        }
    */
  }

  private addSchemaUrlInfo( schema ) {
    if ( schema.url ) {
      this.meta.addTag ( {
        property: 'og:url', content: schema.url.toString ()
                                           .replace ( 'https', 'http' ),
      } );
    }
  }

  private addCanonicalLink( content, url ): void {
    if ( content ) {

      this.doc.querySelectorAll ( 'link[rel="canonical"]' )
          .forEach ( item => {
            item.remove ();
          } );
      const link: HTMLLinkElement = this.doc.createElement ( 'link' );
      link.setAttribute ( 'rel', 'canonical' );
      this.doc.head.appendChild ( link );
      link.setAttribute ( 'href', url );
    }
  }

  private addSeoOgTwitterKeywords( socialMedia ) {
    if ( socialMedia.keywords ) {
      this.meta.addTag ( { property: 'twitter:keywords', content: socialMedia.keywords } );
      this.meta.addTag ( { property: 'og:keywords', content: socialMedia.keywords } );
    }
  }

  private addSeoTwitterOgImg( socialMedia ) {
    if ( socialMedia.image ) {
      const url = socialMedia.image; // .replace( 'https', 'http' );
      this.meta.addTag ( { name: 'twitter:image', content: url } );
      this.meta.addTag ( { name: 'og:image', content: url } );
      this.meta.addTag ( { property: 'og:image', content: url } );
      this.meta.addTag ( { name: 'image', content: url } );
    }
  }

  private addTwitterOgDescription( socialMedia ) {
    if ( socialMedia.description ) {
      this.meta.addTag ( { property: 'twitter:description', content: socialMedia.description } );
      this.meta.addTag ( { property: 'og:description', content: socialMedia.description } );
    }
  }

  private addTwitterOgTitle( socialMedia ) {
    if ( socialMedia.title ) {
      this.meta.addTag ( { property: 'twitter:title', content: socialMedia.title } );
      this.meta.addTag ( { property: 'og:title', content: socialMedia.title } );
    }
  }

  private addDefaultTwitterMetaInfo( socialMedia ) {
    this.meta.addTag ( { name: 'twitter:card', content: 'summary' } );
    if ( socialMedia.twitterId ) {
      this.meta.addTag ( { name: 'twitter:site', content: `${socialMedia.twitterId}` } );
    }
  }

  private addMetaDescriptionAndTitle( seo ) {
    if ( seo.title ) {
      this.title.setTitle ( seo.title );
    }
    if ( seo.description ) {
      this.meta.addTag ( { name: 'description', content: seo.description } );
    }
  }

  private removeOldMetaTags() {
    this.meta.removeTag ( `name='description'` );
    this.meta.removeTag ( `name='twitter:card'` );
    this.meta.removeTag ( `name='twitter:site'` );

    this.meta.removeTag ( `name='twitter:title'` );
    this.meta.removeTag ( `name='og:title'` );
    this.meta.removeTag ( `property='twitter:title'` );
    this.meta.removeTag ( `property='og:title'` );

    this.meta.removeTag ( `name='twitter:description'` );
    this.meta.removeTag ( `name='og:description'` );
    this.meta.removeTag ( `property='twitter:description'` );
    this.meta.removeTag ( `property='og:description'` );

    this.meta.removeTag ( `name='twitter:image'` );
    this.meta.removeTag ( `name='og:image'` );
    this.meta.removeTag ( `property='twitter:image'` );
    this.meta.removeTag ( `property='og:image'` );
    this.meta.removeTag ( `name='image'` );

    this.meta.removeTag ( `name='twitter:keywords'` );
    this.meta.removeTag ( `name='og:keywords'` );
    this.meta.removeTag ( `property='twitter:keywords'` );
    this.meta.removeTag ( `property='og:keywords'` );
    this.meta.removeTag ( `property='og:type'` );
    this.meta.removeTag ( `property='og:url'` );
  }

  stripHtml( html: string ): string {
    return html?.replace ( /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '' ) || '';
  }
}
