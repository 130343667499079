import { BlackbirdPagination, BlackbirdPost } from '@ed-clients/shared';
import { createAction, props } from '@ngrx/store';

// similar to references store, but videos store doesnt support categories
export const VIDEOS_LOAD = createAction ( '[VIDEOS] LOAD VIDEOS',
  props<{ page?: number, limit?: number, query?: string }> ()
);

export const VIDEOS_SET = createAction ( '[VIDEOS] SET VIDEOS',
  props<{ videos: BlackbirdPost[] }> ()
);

export const VIDEOS_LAZY_LOADED_SET = createAction ( '[VIDEOS] LAZY LOADED SET VIDEOS',
  props<{ videos: BlackbirdPost[] }> ()
);

export const VIDEOS_SET_Pagination = createAction ( '[VIDEOS] SET VIDEOS PAGINATION',
  props<{ pagination: BlackbirdPagination }> ()
);
