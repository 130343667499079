<h2 mat-dialog-title>{{data.title}} # {{num}}</h2>
<mat-dialog-content class="mat-typography">
  <div #desc [innerHTML]="data.description"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">

  <a
    [href]="mailto"
    mat-button
  >
    <mat-icon>mail_outline</mat-icon>
  </a>

<!--<button [mat-dialog-close]="data" cdkFocusInitial mat-button>OK</button>-->
  <button mat-button (click)="close()">OK</button>

</mat-dialog-actions>
